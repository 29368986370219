import React from 'react'
import { Routes, Route } from "react-router-dom";
import MediaHome from '../pages/media-center/MediaHome';
import Events from '../pages/media-center/Events';
import Tournament from '../pages/media-center/Tournament';
import Awards from '../pages/media-center/Awards';
import Notices from '../pages/media-center/Notices';
import CommanNav from '../components/globles/CommanNav';
import Collection from '../pages/media-center/Collection';
import Request from '../pages/game-management/Requests';

const MediaCenter = () => {
  return (
    <Routes>
        <Route path="/media-center" element={<CommanNav />}>
            <Route index element={<MediaHome />} />
            <Route path="events" element={<Events />} />
            <Route path="tournament" element={<Tournament />} />
            <Route path="awards" element={<Awards />} />
            <Route path="notices" element={<Notices />} />
            <Route path="collection" element={<Collection />} />
            <Route path="request" element={<Request />} />

        </Route>
    </Routes>
  )
}

export default MediaCenter