import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowForwardIcon, InfoIcon } from '@chakra-ui/icons';

// EXPENSE CARD 
// EXPENSE CARD
const ExpenseCard = ({ expense, color, icon, onHandleClick, dynamicCategory, brColor }) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const isArabic = i18n.language === 'ar';
    const navigate = useNavigate();

    const totalValue = expense?.value || expense?.totalAmountGenerated || expense?.totalAmountExpensed || 0;
    return (
        <div
            onClick={() => {
                if (dynamicCategory) {
                    onHandleClick();
                    return
                }
                if (expense?.kind?.includes('additional-expenses')) {
                    navigate(`/account/additional-expenses`);
                } else {
                    if (!location?.pathname?.includes("balancesheet")) {

                        navigate(`/account/expenses/${expense?.kind || expense?.name}`)
                    }
                }
            }}
            // style={{ backgroundColor: color }}
            style={{
                background: color,
                // color: brColor,
                borderColor: brColor
            }}
            className={` transition-all duration-300 ${brColor ? 'border-l-4' : ''}   flex flex-col ${isArabic ? 'items-end justify-end ' : 'items-start justify-start'} hover:shadow-md gap-2 w-full py-6 px-8 rounded-xl   mb-4`}
        >
            <div className={`flex ${isArabic ? 'flex-row-reverse' : 'flex-row'} items-center  gap-2`}>
                {icon}
                <h6 className={`text-sm whitespace-nowrap lg:text-base font-bold capitalize`}>{t(expense?.label || expense?.name)}</h6>
            </div>
            <h4 style={{ color: brColor }} className="text-xl lg:text-2xl font-bold">
                SAR     {totalValue?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </h4>
            {/* <div className="w-full flex items-center  gap-2">
                <ArrowForwardIcon style={{ rotate: '-45deg' }} w={3} color="gray.500" />
                <h6 className="text-sm whitespace-nowrap lg:text-sm text-gray-600 font-medium">Since last year</h6>
            </div> */}
        </div>
    )
}

export default ExpenseCard