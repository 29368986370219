import React, { useEffect, useState } from 'react';
import { get } from '../../services/apis/api';
import { Skeleton } from '@chakra-ui/react';
import EarnignsAndSpendingsSummery from '../../components/account/EarnignsAndSpendingsSummery';
import RequestSummery from '../../components/account/RequestSummery';
import ExpensesSummery from '../../components/account/ExpensesSummery';
import HrExpensesAssetsOverview from '../../components/hr/components/reports/HrExpensesAssetsOverview';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const ReportsAndSummery = () => {
    const [reportsData, setReportsData] = useState([]);

    // FETCH REPORTS GRAPHS DATA
    const getReportsGraph = async () => {
        const res = await get('finance-reports/transactions/graph-data');
        if (res.statusCode === 200) {
            setReportsData(res?.data);
        }
    };

    useEffect(() => {
        getReportsGraph();
    }, []);

    // DOWNLOAD FULL PAGE AS PDF
    const downloadFullPageAsPdf = async () => {
        const pageElement = document.getElementById('reports-and-summary-page'); // Target the full page
        const canvas = await html2canvas(pageElement, {
            scale: 2, // High-quality output
            useCORS: true, // Handle cross-origin images
        });

        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = 210; // A4 width in mm
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

        // Split content into pages if necessary
        let currentHeight = 0;
        while (currentHeight < canvas.height) {
            const sourceCanvas = document.createElement('canvas');
            const context = sourceCanvas.getContext('2d');
            sourceCanvas.width = canvas.width;
            sourceCanvas.height = pdf.internal.pageSize.height * (canvas.width / pdfWidth);

            context.drawImage(
                canvas,
                0,
                currentHeight,
                canvas.width,
                sourceCanvas.height,
                0,
                0,
                sourceCanvas.width,
                sourceCanvas.height
            );

            const pageImage = sourceCanvas.toDataURL('image/png');
            pdf.addImage(pageImage, 'PNG', 0, 0, pdfWidth, pdfHeight);

            currentHeight += sourceCanvas.height;
            if (currentHeight < canvas.height) pdf.addPage();
        }

        pdf.save('ReportsAndSummary.pdf');
    };

    return (
        <div id="reports-and-summary-page" className="w-full flex-1 flex flex-col p-2 lg:p-4">
            <div className="flex justify-between px-0 lg:px-2 mb-4">
                <h2 className="text-xl font-bold mb-4">Reports & Summary</h2>
                <button
                    className="px-4 py-2 bg-blue-500 text-white rounded"
                    onClick={downloadFullPageAsPdf}
                >
                    Download PDF
                </button>
            </div>
            <div className="bg-gray-100">
                <div className="w-full p-6 bg-gray-100 flex gap-4 lg:gap-6 items-center justify-between rounded">
                    <div className="w-full bg-white rounded">
                        {reportsData?.earningsGraph ? (
                            <EarnignsAndSpendingsSummery
                                graphData={reportsData?.earningsGraph || []}
                                currentYear={reportsData?.currentYear || ''}
                            />
                        ) : (
                            <Skeleton height="300px" />
                        )}
                    </div>
                </div>
                <div className="w-full p-6 bg-gray-100 flex-col lg:flex-row flex gap-4 lg:gap-6 items-center justify-between rounded">
                    {reportsData?.requestStats ? (
                        <RequestSummery
                            requestsData={reportsData?.requestStats || []}
                        />
                    ) : (
                        <Skeleton width="50%" marginRight={3} height="300px" />
                    )}
                    {reportsData?.expensesByRefType ? (
                        <ExpensesSummery
                            expensesData={reportsData?.expensesByRefType || []}
                        />
                    ) : (
                        <Skeleton width="50%" height="300px" />
                    )}
                </div>
                {reportsData?.assets ? (
                    <HrExpensesAssetsOverview
                        data={reportsData?.monthlyHRExpenditure || []}
                        assets={reportsData?.assets || []}
                        expensesChartData={
                            reportsData?.monthlySourceTotals || []
                        }
                    />
                ) : (
                    <div className="w-full flex items-center justify-between">
                        <Skeleton
                            marginRight={3}
                            width="50%"
                            height="300px"
                        />
                        <Skeleton width="50%" height="300px" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReportsAndSummery;
