import React, { useCallback, useEffect, useState } from 'react'
import { UserState } from '../../context/user';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDisclosure, useToast } from '@chakra-ui/react';
import BasicDataComponent from '../../components/account/directIncomeCategory/BasicDataComponent';
import { useTranslation } from 'react-i18next';
import SelectYearComponent from '../../components/account/SelectYearComponent';
import TableComponent from '../../components/account/TableComponent';
import { get, post } from '../../services/apis/api';
import CompletedPayment from '../../components/account/overdues/CompletePayment';
import DateRangeSelect from '../../components/account/DateRangeSelect';
import { debounce } from 'lodash';
import ConfirmRemoveTransaction from '../../components/account/overdues/ConfirmRemoveTransaction';

const Heading = ({ title }) => (
    <h2 className="text-base text-gray-600 mb-2 lg:text-xl font-bold">{title}</h2>
)

// TABLE COLUMNS 
const columns = [
    's-no',
    'amount',
    'created-by',
    'created-at',
    'actions' //QUETIONS IT SHOLD GIVE HERE OR NOT 
];


const _formData = {
    entryDate: '',
    amount: null,
    description: '',
    transactionType: 'DR',
    paymentType: 'offline',
    paymentMethod: 'cash',
    paymentId: '',
    paymentState: 'PAID',
    transactionId: ''
}

const OverDuePayments = () => {
    const toast = useToast();
    const { user } = UserState();
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [totalAmountDue, setTotalAmountDue] = useState(0);
    const [page, setPage] = useState(1);
    const [skip, setSkip] = useState(0); // Initial skip should be 0
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedTransaction, setSelectedTransaction] = useState(new Date().getFullYear());
    const [transactionsData, setTransactionsData] = useState(null)
    const [searchTerm, setSearchTerm] = useState('');
    const { isOpen: isConfirmOpen, onOpen: onOpenConfirm, onClose: onCloseConfirm } = useDisclosure();

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoading, setIsLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({ ..._formData })
    const [date, setDate] = useState({
        startDate: '',
        endDate: ''
    });

    // FETCH ALL TRRANSECTIONS BY API'S 
    const getAllTransactions = async (skip, limit, searchTerm, startDate, endDate) => {
        try {
            if (user) {
                const res = await get(`expenses/getAllTransactions/unpaid?skip=${skip}&limit=${limit}&search=${searchTerm}&startDate=${startDate}&endDate=${endDate}&transactionType=DR`);
                setTransactionsData(res?.data?.data);
                const totalCount = res?.data?.totalCount || 0; // Ensure totalCount is defined
                setTotalPages(Math.ceil(totalCount / limit)); // Calculate total pages
                setTotalAmountDue(res?.data?.totalAmount)
            }
        } catch (error) {
            console.log(error);
        }
    };

    // SELECT CURRENT SELECTED TRANSACTION 
    const onSelectEditTransaction = (txn) => {
        try {
            setFormData((prevData) => ({
                ...prevData, // Spread the previous data correctly
                amount: txn?.amount,// Update the amount field with the transaction amount
                transactionId: txn?._id,
                description: txn?.description
            }));
            onOpen();
        } catch (e) {
            console.log(`ERRRO WHILE SETTING EDIT TRANSACTION ${e}`);
        }
    };

    useEffect(() => {
        getAllTransactions(skip, limit, searchTerm, date?.startDate, date?.endDate);
    }, [page, limit]);

    // Use useCallback to memoize the function so it doesn't recreate on every render
    const debouncedGetAllRequest = useCallback(
        debounce((...args) => {
            getAllTransactions(...args);
        }, 300), // Adjust delay as needed
        []
    );

    useEffect(() => {
        // Debounced API call
        debouncedGetAllRequest(0, 10, searchTerm, date?.startDate, date?.endDate);

        // Cleanup function to cancel the debounce on component unmount
        return () => {
            debouncedGetAllRequest.cancel();
        };
    }, [searchTerm, debouncedGetAllRequest]); // Dependencies for the effect


    useEffect(() => {
        if (date.startDate && date.endDate) {
            getAllTransactions(skip, limit, searchTerm, date?.startDate, date?.endDate);
        }
    }, [date]);


    // UPDATE A NEW TRANSACTION 
    const updateTransaction = async () => {
        setIsLoading(true)
        try {
            const _payload = {
                ...formData,
            }
            const res = await post(`expense-category/update-expense`, _payload)
            setIsLoading(false);
            if (res.statusCode === 201 || res.statusCode === 200) {
                toast({
                    title: 'Success!',
                    description: res.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                setFormData({ ..._formData });
                getAllTransactions(skip, limit, searchTerm, date?.startDate, date?.endDate);
                onClose();
            }
        } catch (e) {
            setIsLoading(false);
            console.log(`ERROR WHILE FETCHING DATA:${e}`);
        }
    };


    // REMIVE SELECTED TRANSACTION 
    const removeTransactionFunction = async (txnId) => {
        try {
            const res = await post(`expense-category/delete-expense/${txnId}`);

            setIsLoading(false);
            if (res.statusCode === 200) {
                toast({
                    title: 'Success!',
                    description: res?.data?.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                getAllTransactions(skip, limit, searchTerm, date?.startDate, date?.endDate);
                onCloseConfirm();
            }
        } catch (e) {
            console.log(e);

            console.log(`ERROR WHILE DELETING TRANSACTION ${e}`);
            toast({
                title: 'Success!',
                description: e?.response?.data?.message,
                status: 'errpr',
                duration: 5000,
                isClosable: true,
            });
        }
    }

    return (
        <>
            <div className='p-2 lg:p-4'>
                <button onClick={() => { navigate(-1) }} className="px-4 py-2 text-white bg-[#290dbd] rounded-md hover:bg-[#1d0a8c]">
                    {t("back")}
                </button>
                <div className='w-full flex items-start justify-between py-4 lg:py-6 '>
                    <div className='flex flex-col items-start gap-2'>
                        <h1 className='text-xl lg:text-3xl font-bold mb-4 lg:mb-2 capitalize'>{'OverDues Transactions'}</h1>
                        <h3 className="text-lg lg:text-xl font-semibold text-gray-500">
                            SAR {totalAmountDue?.toLocaleString('en-IN') || '0.00'}
                        </h3>
                    </div>
                    {/* <div className='flex items-center gap-2'>
                        <SelectYearComponent
                            selectedYear={selectedYear}
                            setSelectedYear={setSelectedYear}
                        />
                    </div> */}
                </div>
                <div className="w-full flex flex-col items-start gap-3 flex-1 min-h-[50vh]">
                    <div className={`flex w-full justify-between px-0 lg:px-2 mb-4 gap-2 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
                        <Heading
                            title={"past-transections"}
                        />
                        <div className={`flex ${isArabic ? 'flex-col-reverse lg:flex-row-reverse items-end justify-start' : 'flex-col lg:flex-row items-start'} gap-2 w-full justify-between lg:w-fit `}>
                            <div className="relative mr-3">
                                <div className={`absolute inset-y-0 ${isArabic ? 'start-auto end-0 pe-3' : 'start-0 ps-3'} flex items-center pointer-events-none`}>
                                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    id="table-search-users"
                                    className={`block p-2 ${isArabic ? 'pe-10 text-right' : 'ps-10 text-left'} text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500`}
                                    placeholder={t("search-request")}
                                    value={searchTerm}
                                    onChange={(e) => { setSearchTerm(e.target.value) }}
                                />
                            </div>
                            <DateRangeSelect
                                date={date}
                                setDate={setDate}
                                resetDates={async () => {
                                    console.log('heyyy!');
                                    await setDate({ startDate: '', endDate: '' })
                                    getAllTransactions(skip, limit, searchTerm, '', '');
                                }}
                                getData={() => {
                                    getAllTransactions(skip, limit, searchTerm, date?.startDate, date?.endDate);
                                }}
                            />
                        </div>
                    </div>
                    <div className='w-full flex-1 flex flex-col '>
                        <TableComponent
                            page={page}
                            skip={skip}
                            limit={limit}
                            setSkip={setSkip}
                            setLimit={setLimit}
                            setPage={setPage}
                            columns={columns}
                            rows={transactionsData || []}
                            totalPages={totalPages}
                            onOpenPayment={(txn) => {
                                onSelectEditTransaction(txn);
                            }}
                            onConfirmDelete={(txn) => {
                                setSelectedTransaction(txn);
                                onOpenConfirm();
                            }}
                        />
                    </div>
                </div>
            </div>
            <CompletedPayment
                onOpen={onOpen}
                isOpen={isOpen}
                onClose={onClose}
                formData={formData}
                // category={categoryData?.name}
                onHandleChange={(field, value) => {
                    setFormData((prevData) => ({
                        ...prevData,
                        [field]: value
                    }));
                }}
                isLoading={isLoading}
                onReset={() => {
                    setFormData({ ..._formData });
                    onClose();
                }}
                onHandleSubmit={() => updateTransaction()}
            />

            <ConfirmRemoveTransaction
                isOpen={isConfirmOpen}
                onClose={onCloseConfirm}
                onDeleteTransaction={() => {
                    removeTransactionFunction(selectedTransaction?._id);
                }}
                isLoading={isLoading}
            />
        </>
    )
}

export default OverDuePayments
