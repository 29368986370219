import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  Text,
  Checkbox,
  IconButton,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { DownloadIcon, SearchIcon } from '@chakra-ui/icons';
import AddAttendanceByCSV from '../../components/hr/dialogBoxes/AddAttendanceByCSV';
import AddSingleEmployeeAttendance from '../../components/hr/dialogBoxes/AddSingleEmployeeAttendance';
import { get, post } from '../../services/apis/api';
import { formatDateTime } from '../../utills/formateDate';
import CompanyLeavePolicyModal from '../../components/hr/dialogBoxes/AddLeavePolicy';
import CompanyLeavePolicyDetailsModal from '../../components/hr/dialogBoxes/CompanyLeavePolicyDetailsModal';
import { IoMdAdd } from "react-icons/io";
import DatePicker from 'react-datepicker';
import { CiCalendarDate } from 'react-icons/ci';


// Utility function to convert attendance data to CSV format
const convertToCSV = (data) => {
  const headers = ['Name', 'EmployeeCode', 'PaidDays', 'PayableDays', 'OvertimeHours', 'MonthEndDate'];

  const rows = data.map((attendance) => [
    attendance?.employeeId?.name,
    attendance?.employeeId?.employeeCode,
    attendance.paidDays,
    attendance.payableDays,
    attendance.overTimeHours,
    formatDateTime(attendance.monthEndDate, 'date'),
  ]);

  const csvContent = [headers, ...rows].map((e) => e.join(",")).join("\n");
  return csvContent;
};

const Attendance = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [attendances, setAttendances] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalAttendances, setTotalAttendances] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [loading, setLoading] = useState(false);
  // Initialize state with the current date set to the 30th of the current month
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 0); // Last day of the previous month
  });


  // SELECT ROWS LOGIC 
  const [selectedRows, setSelectedRows] = useState([]);

  // Handle select all
  const handleSelectAll = (e) => {
    try {
      if (e?.target?.checked) {
        // Create a payload by mapping through the rows and formatting each object
        const payload = attendances?.map((row) => ({
          _id: row?._id,
          employeeId: row?.employeeId?._id, // Extract only the ID from employeeId
          monthEndDate: row?.monthEndDate,
          paidDays: row?.paidDays,
          payableDays: row?.payableDays,
          overTimeHours: row?.overTimeHours,
          totalLeave: row?.totalLeave,
          sickLeave: row?.sickLeave,
          casualLeave: row?.casualLeave,
          totalAbsent: row?.totalAbsent,
          organizationId: row?.organizationId,
        }));

        // Set the payload in the state
        setSelectedRows(payload);
      } else {
        // Deselect all rows
        setSelectedRows([]);
      }
    } catch (e) {
      console.log(`ERROR WHILE SELECTING ALL ROWS ${e}`);
    }
  };

  // ADD MULTIPLE EMPLOYEE ATTANDACNE AT ONCE 
  const addMultipleEmployeeAttendance = async () => {
    // if (!formData?.employee_id || !startDate || formData?.paidDays === '' || formData?.payableDays === '' || formData?.overTimeHours === '' || formData?.totalLeave === '' || formData?.totalAbsent === '') {
    //     toast({
    //         title: 'Error',
    //         description: 'All fields are required.',
    //         status: 'error',
    //         duration: 5000,
    //         isClosable: true,
    //     });
    //     return;
    // } 
    setLoading(true);
    try {
      const res = await post('attendance/multiple/add', selectedRows);
      setLoading(false);
      if (res.statusCode === 201) {
        onClose();
        getAttendances();
        toast({
          title: 'Success',
          description: res?.data?.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast({
        title: 'Error',
        description: 'Failed to create attendance record.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle row selection
  const handleRowSelect = (e, row) => {
    try {
      if (e?.target?.checked) {
        // Add the selected row as a formatted payload
        const formattedRow = {
          _id: row?._id,
          employeeId: row?.employeeId?._id, // Extract only the ID from employeeId
          monthEndDate: row?.monthEndDate,
          paidDays: row?.paidDays,
          payableDays: row?.payableDays,
          overTimeHours: row?.overTimeHours,
          totalLeave: row?.totalLeave,
          sickLeave: row?.sickLeave,
          casualLeave: row?.casualLeave,
          totalAbsent: row?.totalAbsent,
          organizationId: row?.organizationId,
        };

        setSelectedRows((prev) => [...prev, formattedRow]);
      } else {
        // Remove the row with matching _id from selectedRows
        setSelectedRows((prev) => prev.filter((r) => r._id !== row._id));
      }
    } catch (e) {
      console.log(`ERROR WHILE SELECTING ROW ${e}`);
    }
  };

  // Check if all rows are selected
  const isAllSelected = selectedRows?.length === attendances?.length && attendances?.length > 0;
  // SELECT ROWS LOGIC 

  // GET ALL EMPLOYEE ATTANDANCE 
  const getAttendances = async (limit = 10) => {
    try {
      if (!startDate) {
        console.error("Start date is not defined");
        return;
      }
      // Calculate the first date of the selected month
      const firstDateOfMonth = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
      // Make the API call with both startDate and the first date of the month
      const res = await get(
        `attendance/all?page=${page}&limit=${limit}&name=${search}&startDate=${firstDateOfMonth}&endDate=${startDate}`
      );
      // const res = await get(`attendance/all?page=${page}&limit=${limit}&name=${search}&startDate=${startDate}&endDate=${startDate}`);
      if (res?.statusCode == 200) {
        setAttendances(res.data.data);
        setTotalPages(res.data.pages);
        setTotalAttendances(res.data.total);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAttendances();
  }, [page, search, startDate]);

  // HANDLE SEARCH CHANGE FUNCTION 
  const handleSearchChange = (e) => {
    try {
      setSearch(e.target.value);
      setPage(1);
    } catch (e) {
      console.log(`ERROR WHILE SEARCHING ${e}`);
    }
  };

  // HANDLE PAGE CHANGE FUNCTION 
  const handlePageChange = (newPage) => {
    try {
      if (newPage >= 1 && newPage <= totalPages) {
        setPage(newPage);
      }
    } catch (e) {
      console.log(`ERROR WHILE CHANGING PAGE ${e}`);
    }
  };

  // Fetch all attendances and then download as CSV
  const downloadCSV = async () => {
    try {
      const res = await get(`attendance/all?page=1&limit=${totalAttendances}&name=${search}`);
      const allAttendances = res.data.data;
      const csvContent = convertToCSV(allAttendances);
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'attendances.csv');
      link.style.visibility = 'hidden';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error fetching attendances for CSV:', error);
    }
  };

  // HANDLE DATE CHANGE FUNCTION 
  const handleDateChange = (date) => {
    try {
      if (date instanceof Date && !isNaN(date)) {
        const currentDate = new Date(); // Get the current date
        const selectedMonth = date.getMonth(); // Get the selected month
        const selectedYear = date.getFullYear(); // Get the selected year

        const currentMonth = currentDate.getMonth(); // Get the current month
        const currentYear = currentDate.getFullYear(); // Get the current year

        // Check if the selected date is in the current or future month
        if (
          selectedYear > currentYear ||
          (selectedYear === currentYear && selectedMonth >= currentMonth)
        ) {
          // console.log("You cannot select the current or a future month.");
          toast({
            title: 'Warning',
            description: 'You cannot select the current or a future month.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        } else {
          const updatedDate = new Date(date.getFullYear(), date.getMonth(), 30);
          setStartDate(updatedDate);
        }
      } else {
        console.log("Invalid date selected");
      }
    } catch (e) {
      console.log(`ERROR WHILE SETTING DATE ${e}`);
    }
  };

  // ATTENDANCE TABLE
  const AttendanceTable = ({ attendances, page, totalPages, handlePageChange }) => (
    <>
      <div className=" overflow-x-auto shadow-md sm:rounded-lg">
        <Table className="w-full text-sm text-left text-gray-500">
          <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <Tr>
              <Th>
                <Checkbox
                  isChecked={isAllSelected}
                  onChange={handleSelectAll}
                />
              </Th>
              <Th>Profile</Th>
              <Th>Name</Th>
              <Th>Employee Code</Th>
              <Th>Paid Days</Th>
              <Th>Payable Days</Th>
              <Th>Overtime Hours</Th> {/* Updated heading */}
              <Th>Month End Date</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {attendances.map((attendance) => (
              <Tr key={attendance?.employeeCode} className="bg-white border-b hover:bg-gray-50 cursor-pointer">
                <Td>
                  <Checkbox
                    // isChecked={selectedRows.includes(attendance?._id)}
                    isChecked={selectedRows.some((row) => row._id === attendance?._id)}
                    onChange={(e) => handleRowSelect(e, attendance)}
                  />
                </Td>
                <Td>
                  <Avatar src={attendance?.profile} name={attendance?.name} />
                </Td>
                <Td>{attendance?.employeeId?.name}</Td>
                <Td>{attendance?.employeeId?.employeeCode}</Td>
                <Td>{attendance.paidDays}</Td>
                <Td>{attendance.payableDays}</Td>
                <Td>{attendance.overTimeHours}</Td> {/* Adjusted field name */}
                <Td>{formatDateTime(attendance.monthEndDate, 'date')}</Td>
                <Td>
                  <IconButton
                    onClick={() => {
                      try {
                        const _data = {
                          ...attendance,
                          employee_id: attendance?.employeeId
                        }
                        delete _data.employeeId
                        setSelectedEmployee(_data)
                        onOpen();
                      } catch (e) {
                        console.log(`ERROR WHILE SETTIGN ATTENDANCE ${e}`);
                      }
                    }}
                    aria-label="Search database"
                  >
                    <IoMdAdd />
                  </IconButton>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
      <Flex justify="space-between" align="center" mt={4}>
        <Button
          onClick={() => handlePageChange(page - 1)}
          isDisabled={page === 1}
        >
          Previous
        </Button>
        <Text>Page {page} of {totalPages}</Text>
        <Button
          onClick={() => handlePageChange(page + 1)}
          isDisabled={page >= totalPages}
        >
          Next
        </Button>
      </Flex>
    </>
  );

  return (
    <>
      <Box width="100%" p={4}>
        <Flex justify="space-between" align="center" mb={4}>
          <Box fontSize="xl" fontWeight="bold" mb={{ base: 4, md: 0 }}>
            Attendance ({totalAttendances})
          </Box>
          <Flex align="center">
            <InputGroup mr={2} mb={{ base: 2, md: 0 }} w={{ base: "100%", md: "auto" }}>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search"
                value={search}
                onChange={handleSearchChange}
              />
            </InputGroup>
            <InputGroup mr={2} w="full" maxW="240px">
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                placeholderText="Select Month"
                className="border rounded-md p-2 w-[240px]"
              />
              <CiCalendarDate className="absolute right-3 top-1/2 transform -translate-y-1/2 text-xl text-gray-500 pointer-events-none" />
            </InputGroup>
            <Button
              leftIcon={<IoMdAdd />}
              colorScheme="blue"
              mr={2}
              mb={{ base: 2, md: 0 }}
              onClick={addMultipleEmployeeAttendance}
              isDisabled={selectedRows?.length == 0}
              isLoading={loading}
            >
              Attendance
            </Button>
            <AddAttendanceByCSV />
            <Button
              leftIcon={<DownloadIcon />}
              colorScheme="gray"
              mr={2}
              mb={{ base: 2, md: 0 }}
              onClick={downloadCSV}
            >
              CSV
            </Button>
            {/* <CompanyLeavePolicyModal /> */}
            <CompanyLeavePolicyDetailsModal />
          </Flex>
        </Flex>

        <AttendanceTable
          attendances={attendances}
          page={page}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </Box>
      {isOpen ?
        <AddSingleEmployeeAttendance
          getAttendances={getAttendances}
          selectedMonth={startDate}
          selectedData={selectedEmployee}
          isOpen={isOpen}
          onClose={onClose}
        />
        : null}
    </>
  );
};

export default Attendance;
