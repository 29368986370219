import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    Input,
    FormLabel,
    Button,
    Select,
    useToast,
    Textarea
} from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { Select as ReactSelect } from 'chakra-react-select';
import { useLocation } from 'react-router-dom';
import SelectTransactionStateComponent from './SelectTransactionStateComponent';

const _methodType = [
    'cash',
    'online',
    'check'
]

function AddexpenseCategoryTransaction({ isOpen, heading, allPlayersData, onClose, onReset, allGamesData, formData, onHandleChange, isLoading, onHandleSubmit }) {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const location = useLocation();
    const options = allPlayersData?.length > 0
        ? allPlayersData?.map((player) => ({
            label: t(player?.userName || player?.name),
            value: player?._id,
            gameId: player?.gameId
        }))
        : allGamesData?.map((game) => ({
            label: t(game?.name),
            value: game?._id,
        }));

    const handleChange = (option) => {
        // setSelectedOption(option);
        console.log('Selected option:', option);
        if (location?.pathname?.includes('game')) {
            onHandleChange('game', option)
        } else {
            onHandleChange('player', option)
        }
    };

    return (
        <>
            {/* <Button >Open Modal</Button> */}
            <Modal
                // initialFocusRef={initialRef}
                // finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{heading}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        <SelectTransactionStateComponent
                            selectedStatus={formData?.paymentState}
                            onSelelectState={(value) => {
                                onHandleChange('paymentState', value); // Use the value directly
                            }}
                        />

                        <FormControl mt={4}>
                            <FormLabel>{allGamesData?.length > 0 ? 'Games' : 'Players'} <span className='text-red-600'>*</span></FormLabel>
                            <ReactSelect
                                placeholder={t('select')}
                                options={options}
                                isClearable
                                isSearchable
                                onChange={handleChange}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        textAlign: isArabic ? 'right' : 'left',
                                        background: '#f9fafb',
                                        borderColor: 'gray.300',
                                    }),
                                }}
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Amount <span className='text-red-600'>*</span></FormLabel>
                            <Input
                                value={formData?.amount}
                                type='number'
                                onChange={(e) => {
                                    onHandleChange('amount', e.target.value)
                                }}
                                placeholder='Amount'
                            />
                        </FormControl>
                        {formData?.paymentState == 'PAID' ?
                            <>
                                <FormControl mt={4}>
                                    <FormLabel>Date <span className='text-red-600'>*</span></FormLabel>
                                    <Input
                                        type='date'
                                        value={formData?.entryDate}
                                        onChange={(e) => {
                                            const selectedDate = new Date(e.target.value);
                                            const today = new Date();
                                            // Check if the selected date is greater than today
                                            if (selectedDate <= today) {
                                                onHandleChange('entryDate', e.target.value);
                                            } else {
                                                alert("The selected date cannot be greater than today.");
                                            }
                                        }}
                                        placeholder='Date'
                                    />
                                </FormControl>
                                <FormControl mt={4}>
                                    <FormLabel>{t('select-payment-method')}<span className='text-red-600'>*</span></FormLabel>
                                    <Select
                                        placeholder={t('select-payment-method')}
                                        textAlign={isArabic ? 'right' : 'left'}
                                        bg="#f9fafb"
                                        borderColor="gray.300"
                                        value={formData?.paymentMethod}
                                        onChange={(e) => {
                                            onHandleChange('paymentMethod', e?.target?.value); // Call the function with course name
                                        }}
                                    >
                                        {_methodType?.map((method) => (
                                            <option key={method} value={method}>
                                                {t(method)}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                                {formData.paymentMethod != 'cash' && (
                                    <FormControl mt={4}>
                                        <FormLabel>{formData.paymentMethod === 'check' ? t('check-number') : t('transaction-id')}<span className='text-red-600'>*</span></FormLabel>
                                        <Input
                                            type="text"
                                            placeholder={formData.paymentMethod === 'check' ? t('enter-check-number') : t('enter-transaction-id')}
                                            value={formData?.paymentId}
                                            onChange={(e) => onHandleChange('paymentId', e?.target?.value)} // Update paymentId
                                        />
                                    </FormControl>
                                )}
                            </>
                            : null}
                        <FormControl mt={4}>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                value={formData?.description}
                                onChange={(e) => {
                                    onHandleChange('description', e.target.value)
                                }}
                                placeholder='Entry Description'
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={4}
                            onClick={onReset}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={onHandleSubmit}
                            colorScheme='blue'
                            isLoading={isLoading}
                            isDisabled={
                                formData?.paymentState === 'PAID'
                                    ? (
                                        !formData?.entryDate || // Entry date is mandatory
                                        !formData?.amount ||    // Amount is mandatory
                                        (!formData?.game && !formData?.player) || // Either game or player is mandatory
                                        (formData?.paymentMethod !== 'cash' && !formData?.paymentId) // If payment method is not cash, paymentId is mandatory
                                    )
                                    : !formData?.amount
                            }
                        >
                            Add
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    )
}

export default AddexpenseCategoryTransaction