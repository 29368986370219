import React, { useState } from 'react'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    useDisclosure,
    Button,
    Tooltip,
    Input,
    useToast,
} from '@chakra-ui/react'
import { deleteToken } from '../../services/apis/token'
import { useNavigate } from 'react-router-dom'
import { UserState } from '../../context/user'
import { RxCrossCircled } from 'react-icons/rx'
import { patch } from '../../services/apis/api'

const ConfirmRejectBox = ({ handlePaySlipRequest, onCloseModel, label, request, fetchData }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast();
    const { user, setUser } = UserState();
    const cancelRef = React.useRef()
    const navigate = useNavigate();
    const [reason, setReason] = useState('');

    // REJECT PAYSLIP REQUEST 
    const rejectPaySlipRequest = async (decision) => {
        try {
            const _payload = {
                paymentStatus: decision,
                reason: reason
            }
            const response = await patch(`payroll/update/${request?._id}`, _payload);
            console.log(response, 'sddfsssss');

            if (response.statusCode === 200) {
                if (fetchData) {
                    fetchData();
                }
                onClose();
                if (onCloseModel) {
                    onCloseModel();
                }
                toast({
                    title: 'Successfull...!',
                    description: 'Payroll Request Rejected',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (e) {
            console.log(`ERROR WHILE UPDATING PAYSLIP DATA : ${e}`);
        }
    }

    return (
        <>
            <Tooltip label="Reject" aria-label="Reject tooltip">
                <Button
                    variant="ghost"
                    colorScheme="red"
                    size="sm"
                    aria-label="Reject"
                    onClick={onOpen}
                    leftIcon={<RxCrossCircled size={20} />}
                >
                    {label ? 'Reject' : ''}
                </Button>
            </Tooltip>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Reject Request
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Please add a reason to reject this payroll request ?
                            <Input
                                mt={3}
                                value={reason}
                                onChange={(e) => setReason(e?.target?.value)}
                                placeholder='Add Reason'
                            />
                        </AlertDialogBody>

                        <AlertDialogFooter>

                            <Button
                                variant={"ghost"}
                                size={'sm'} colorScheme='red' onClick={() => {
                                    rejectPaySlipRequest('REJECTED');
                                }} mr={3}>
                                REJECT
                            </Button>
                            <Button
                                variant='solid'
                                colorScheme='blue'
                                size={'sm'}
                                ref={cancelRef}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default ConfirmRejectBox