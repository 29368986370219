import { Button, Select } from '@chakra-ui/react'
import React from 'react'
import SelectYearComponent from '../SelectYearComponent'

const BasicDataComponent = ({
    basicData,
    onAddOpen,
    selectedYear,
    setSelectedYear,
    isDesc,
    isExpense,
    totalAmount
}) => {
    const currentYear = new Date().getFullYear(); // Get the current year
    const years = Array.from({ length: currentYear - 2000 + 1 }, (_, i) => 2000 + i); // Generate years from 2000 to current year

    return (
        <>
            <div className='flex flex-col items-start gap-2'>
                <h1 className='text-xl lg:text-3xl font-bold mb-4 lg:mb-2 capitalize'>Name : {basicData?.source || basicData?.name || ''}</h1>
                <h3 className="text-lg lg:text-xl font-semibold text-gray-500">
                    {
                        isExpense
                            ? `Total Expenses: SAR ${totalAmount?.toLocaleString('en-IN') || '--'}` // Show totalExpensesAmount or '--' if it's not available
                            : `Total Earnings: SAR ${totalAmount?.toLocaleString('en-IN') || basicData?.totalAmountGenerated?.toLocaleString('en-IN') || '--'}` // Show totalAmount or totalTransactionsAmount or totalAmountGenerated, and fallback to '--'
                    }
                </h3>
                {/* <p className='text-sm w-full lg:w-10/12 lg:text-base whitespace-nowrap text-gray-600'>Income Type : offline </p> */}
                {isDesc ?
                    <p className='text-sm w-full lg:w-10/12 lg:text-base'>{basicData?.description || '--'} </p>
                    : null}
            </div>
            <div className='flex items-center gap-2'>
                <SelectYearComponent
                    selectedYear={selectedYear}
                    setSelectedYear={setSelectedYear}
                />
                <Button
                    variant="solid"
                    paddingX={6}
                    colorScheme='blue'
                    onClick={onAddOpen}
                >
                    Add New
                </Button>
            </div>
        </>
    )
}

export default BasicDataComponent
