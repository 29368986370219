import React from 'react';
import {
  Box, Checkbox, FormControl, FormLabel, Radio, RadioGroup, Stack, Grid, GridItem, VStack, Heading
} from '@chakra-ui/react';
import { EmployeeState } from '../../../../context/addEmployee';
import { t } from 'i18next';

const benefitOptions = [
  { label: 'accommodation', name: 'accommodation' },
  { label: 'education-expense', name: 'educationExpense' },
  { label: 'club-expense', name: 'clubExpense' },
  { label: 'credit-card', name: 'creditCard' },
  { label: 'vehicle-expense', name: 'vehicleExpense' },
  { label: 'gift-&-vouchers', name: 'giftVouchers' },
  { label: 'traveling-expense', name: 'travelingExpense' },
  { label: 'house-help', name: 'houseHelp' },
  { label: 'medical-expense', name: 'medicalExpense' },
];

const Benefits = () => {
  const { benefits, setBenefits } = EmployeeState();
  // console.log("benefits-->", benefits);

  const handleCheckboxChange = (name) => (e) => {
    setBenefits((prevState) => ({
      ...prevState,
      [name]: e.target.checked ? prevState[name] || 'Not Applicable' : undefined,
    }));
  };

  const handleRadioChange = (name) => (value) => {
    setBenefits((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Box p={5} borderRadius="md" mx="auto">
      <VStack spacing={4} align="stretch">
        <Heading size="md" mb={4}>{t("benefits")}</Heading>
        <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={4}>
          {benefitOptions?.map((benefit) => (
            <GridItem key={benefit.name} colSpan={{ base: 1, md: 1 }}>
              <FormControl>
                <FormLabel>{t(benefit.label)}</FormLabel>
                <Checkbox
                  isChecked={benefits[benefit.name] !== undefined}
                  onChange={handleCheckboxChange(benefit.name)}
                >
                  <RadioGroup
                    value={benefits[benefit.name]}
                    onChange={handleRadioChange(benefit.name)}
                    isDisabled={benefits[benefit.name] === undefined}
                  >
                    <Stack direction="row">
                      <Radio value="Not Applicable">{t("not-applicable")}</Radio>
                      <Radio value="Inkind">{t("inkind")}</Radio>
                      <Radio value="One Time Reimbursement">{t("one-time-reimbursement")}</Radio>
                      <Radio value="Annual Reimbursement">{t("annual-reimbursement")}</Radio>
                    </Stack>
                  </RadioGroup>
                </Checkbox>
              </FormControl>
            </GridItem>
          ))}
        </Grid>
      </VStack>
    </Box>
  );
};

export default Benefits;
