const BalanceSheetTamplate = ({
    allDirectDebits,
    totalCredits,
    additionalDebits,
    totalDirectDebit,
    totalIndirectDebit,
    allIndirectDebits,
    totalIndirectCredits,
    allIndirectCredits,
    additionalCredits,
    allDirectCredits,
    expensesTotal,
    incomeTotal,
    overDueAmount,
    allGameDebits,
    totalGameDebits
}) => {

    const MainHeading = ({
        title
    }) => (
        <div className='w-full py-4 px-2'>
            <h5 className='text-[#999999] text-sm lg:text-base font-bold capitalize'>{title}</h5>
        </div>
    )

    const ListComponent = ({
        title,
        value
    }) => (
        <div className='w-full flex items-center justify-between'>
            <h5 className='text-[#999999] text-xs lg:text-sm font-medium capitalize'>{title}</h5>
            <h5 className='text-[#999999] text-xs lg:text-sm font-medium capitalize'>
                {value?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </h5>
        </div>
    )

    const ShowTotalComponent = ({
        title,
        value
    }) => (
        <div className='w-full py-2 p-4 flex items-center justify-between bg-[#f3f3f3]'>
            <h5 className='text-[#999999] text-sm lg:text-base font-bold capitalize'>{title}</h5>
            <h5 className='text-[#999999] text-sm lg:text-base font-bold capitalize'>
                {value?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </h5>
        </div>
    )

    return (
        <>
            <div className='w-full flex bg-white border p-4 shadow-md  rounded-sm'>
                <div className='w-full h-full  flex flex-col items-start'>
                    <div className='w-full bg-[#f0f8fc] p-4 flex flex-col items-start gap-2'>
                        <div className='w-full'>
                            <h6 className='uppercase text-sm lg:text-base font-semibold'>credit</h6>
                        </div>
                        <div className='w-full h-1 bg-[#6eb8df]'></div>
                        <div className='w-full flex justify-between items-center'>
                            <h6 className='uppercase text-sm lg:text-base font-semibold'>category</h6>
                            <h6 className='uppercase text-sm lg:text-base font-semibold'>amount</h6>
                        </div>
                    </div>
                    <div className='w-full flex-1 flex flex-col items-start justify-between'>
                        <div className="w-full">
                            {/* DIRECT INCOMES  */}
                            <div className='w-full'>
                                <MainHeading
                                    title={"direct-incomes"}
                                />
                                <div className='w-full flex flex-col items-start gap-2 lg:gap-3 px-4 pb-4'>
                                    {allDirectCredits && allDirectCredits?.length > 0 ?
                                        allDirectCredits?.map((debit, index) => (
                                            <ListComponent
                                                key={index}
                                                title={debit?.label}
                                                value={debit?.value}
                                            />
                                        ))
                                        : null}
                                </div>
                                <ShowTotalComponent
                                    title={"total-direct-expenses"}
                                    value={totalCredits}
                                />
                            </div>
                            {/* DIRECT INCOMES  */}
                            <div className='w-full'>
                                <MainHeading
                                    title={"indirect-incomes"}
                                />
                                <div className='w-full flex flex-col items-start gap-2 lg:gap-3 px-4 pb-4'>
                                    {allIndirectCredits && allIndirectCredits?.length > 0 ?
                                        allIndirectCredits?.map((debit, index) => (
                                            <ListComponent
                                                key={index}
                                                title={debit?.categoryName}
                                                value={debit?.totalAmount}
                                            />
                                        ))
                                        : null}
                                </div>
                                <ShowTotalComponent
                                    title={"total-indirect-expenses"}
                                    value={totalIndirectCredits}
                                />
                            </div>
                            {/* ADDITIOAL INCOMES ÷ */}
                            <div className='w-full mb-4'>
                                <MainHeading
                                    title={"additional-income"}
                                />
                                <div className='w-full flex flex-col items-start gap-2 lg:gap-3 p-4'>
                                    <ListComponent
                                        title={additionalCredits?.label}
                                        value={additionalCredits?.value}
                                    />
                                </div>
                                <ShowTotalComponent
                                    title={"total-additional-income"}
                                    value={additionalCredits?.value}
                                />
                            </div>
                        </div>

                        <ShowTotalComponent
                            title={"total-credit"}
                            value={incomeTotal || 0}
                        />
                    </div>
                </div>
                <div className='w-1 h-full bg-[#e5e6e6]'></div>
                <div className='w-full h-full '>
                    <div className='w-full bg-[#f0f8fc] p-4 flex flex-col items-start gap-2'>
                        <div className='w-full'>
                            <h6 className='uppercase text-sm lg:text-base font-semibold'>debit</h6>
                        </div>
                        <div className='w-full h-1 bg-[#6eb8df]'></div>
                        <div className='w-full flex justify-between items-center'>
                            <h6 className='uppercase text-sm lg:text-base font-semibold'>category</h6>
                            <h6 className='uppercase text-sm lg:text-base font-semibold'>amount</h6>
                        </div>
                    </div>
                    <div className='w-full  flex flex-col items-start'>
                        <div className="w-full flex-1 flex flex-col items-start justify-between ">
                            {/* DIRECT EXPENSES  */}
                            <div className='w-full'>
                                <MainHeading
                                    title={"direct-expenses"}
                                />
                                <div className='w-full flex flex-col items-start gap-2 lg:gap-3 px-4 pb-4'>
                                    {allDirectDebits && allDirectDebits?.length > 0 ?
                                        allDirectDebits?.map((debit, index) => (
                                            <ListComponent
                                                key={index}
                                                title={debit?.label}
                                                value={debit?.value}
                                            />
                                        ))
                                        : null}
                                </div>
                                <ShowTotalComponent
                                    title={"total-direct-expenses"}
                                    value={totalDirectDebit}
                                />
                            </div>
                            {/* GAME EXPENSES  */}
                            <div className='w-full'>
                                <MainHeading
                                    title={"game-expenses"}
                                />
                                <div className='w-full flex flex-col items-start gap-2 lg:gap-3 px-4 pb-4'>
                                    {allGameDebits && allGameDebits?.length > 0 ?
                                        allGameDebits?.map((debit, index) => (
                                            <ListComponent
                                                key={index}
                                                title={debit?.label}
                                                value={debit?.value}
                                            />
                                        ))
                                        : null}
                                </div>
                                <ShowTotalComponent
                                    title={"total-game-expenses"}
                                    value={totalGameDebits}
                                />
                            </div>

                            {/* IN-DIRECT EXPENSES  */}
                            <div className='w-full'>
                                <MainHeading
                                    title={"indirect-exense"}
                                />
                                <div className='w-full flex flex-col items-start gap-2 lg:gap-3 px-4 pb-4'>
                                    {allIndirectDebits && allIndirectDebits?.length > 0 ?
                                        allIndirectDebits?.map((debit, index) => (
                                            <ListComponent
                                                key={index}
                                                title={debit?.categoryName}
                                                value={debit?.totalAmount}
                                            />
                                        ))
                                        : null}
                                </div>
                                <ShowTotalComponent
                                    title={"total-indirect-expenses"}
                                    value={totalIndirectDebit}
                                />
                            </div>
                            <div className='w-full mb-4'>
                                <MainHeading
                                    title={"additional-exense"}
                                />
                                <div className='w-full flex flex-col items-start gap-2 lg:gap-3 p-4'>
                                    <ListComponent
                                        title={additionalDebits?.label}
                                        value={additionalDebits?.value}
                                    />
                                </div>
                                <ShowTotalComponent
                                    title={"total-additional-expenses"}
                                    value={additionalDebits?.value}
                                />
                            </div>
                            <div className='w-full mb-4'>
                                <MainHeading
                                    title={"overdue-expenses"}
                                />
                                <div className='w-full flex flex-col items-start gap-2 lg:gap-3 p-4'>
                                    <ListComponent
                                        title={'overdue-amount'}
                                        value={overDueAmount}
                                    />
                                </div>
                                <ShowTotalComponent
                                    title={"total-overdue-expenses"}
                                    value={overDueAmount}
                                />
                            </div>
                        </div>
                        <ShowTotalComponent
                            title={"total-debit"}
                            value={expensesTotal || 0}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default BalanceSheetTamplate