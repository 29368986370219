import React, { useEffect, useState } from 'react'
import { UserState } from '../../context/user';
import { useTranslation } from 'react-i18next';
import { ExpensesCardSkeleton } from '../../components/skeletons/Skeletons';
import { useNavigate } from 'react-router-dom';
import { get } from '../../services/apis/api';
import BalanceSheetTamplate from '../../components/account/BalansheetTamplate';
import SelectYearComponent from '../../components/account/SelectYearComponent';
import ExpenseCard from './ExpenseCard';

// CATEGORY CARD COLORS 
const cardColors = [
    {
        bg: "#f0fbff", // Very light cyan (background)
        color: "#0277bd", // Deep blue for text (contrast against bg)
        iconColor: "#0277bd", // Match icon color with text for consistency
        icon: '🏦' // Updated to match iconColor
    },
    {
        bg: "#ffebee", // Light red (background)
        color: "#ef4444", // Deep red (text for strong contrast)
        iconColor: "#d32f2f", // Muted medium red for icons
        icon: '💸' // Example icon color
    },
    {
        bg: "#e8f5e9", // Softer light green (background)
        color: "#4caf50", // Medium green (text with lighter contrast)
        iconColor: "#66bb6a", // Light green (for icons)
        icon: '📊' // Example icon
    },
    {
        bg: "#FFD6D6", // Light red/pink (background)
        color: "#D32F2F", // Deep red (text for strong contrast)
        iconColor: "#C62828", // Medium red (for icons)
        icon: '📅' // Example icon
    },
]

const BalanceSheet = () => {
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const navigate = useNavigate();
    const [balanceSheetData, setBalanceSheetData] = React.useState(null);
    const [allDirectDebits, setAllDirectDebits] = useState(null)
    const [allGameDebits, setAllGameDebits] = useState(null)
    const [totalGameDebits, setTotalGameDebits] = useState(null)
    const [allIndirectDebits, setAllIndirectDebits] = useState(null)
    const [totalDirectDebit, setTotalDirectDebit] = useState(null)
    const [expensesTotal, setexpensesTotal] = useState(null)
    const [incomeTotal, setIncomeTotal] = useState(null)
    const [totalIndirectDebit, setTotalIndirectDebit] = useState(null)
    const [additionalDebits, setAdditionalDebits] = useState(null)
    const [allDirectCredits, setAllDirectCredits] = useState(null)
    const [allIndirectCredits, setAllIndirectCredits] = useState(null)
    const [totalCredits, setTotalCredits] = useState(null)
    const [totalIndirectCredits, setTotalIndirectCredits] = useState(null)
    const [additionalCredits, setAdditionalCredits] = useState(null)
    const [overDueAmount, setOverDueAmount] = useState(0)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    console.log(balanceSheetData, 'balanceSheetData------------>');

    // // FETCH BALANCE SHEET DATA 
    // const getBalanceSheetData = async () => {
    //     try {
    //         const res = await get(`finance-reports/balance-sheet?year=${selectedYear}`);
    //         if (res.statusCode === 200) {
    //             setBalanceSheetData(res?.data);
    //         }
    //     } catch (e) {
    //         console.log(`ERROR WHILE FETCHING DATA:${e}`);

    //     }
    // }

    // GET TOTAL DIRECT INCOMES BY API
    const getTotalBalanceSheetDetails = async () => {
        try {
            const res = await get(`finance-reports/balance-sheet-data?year=${selectedYear}`);
            // console.log(res,'response blan details----');

            if (res.statusCode === 200) {
                const allData = res?.data || [];
                setAllDirectDebits(allData?.directExpenses); // Total excluding "additional-expenses"
                setexpensesTotal(allData?.expensesTotal);
                setIncomeTotal(allData?.incomeTotal);
                setTotalDirectDebit(allData?.totalDirectDebit)
                setAllIndirectDebits(allData?.indirectExpenses?.categories);
                setAllIndirectCredits(allData?.indirectIncomes?.categories);
                setTotalIndirectCredits(allData?.indirectIncomes?.grandTotal);
                setTotalIndirectDebit(allData?.indirectExpenses?.grandTotal); // Store the total of all totalAmountExpensed
                setAdditionalDebits(allData?.additionalExpenses);
                setAdditionalCredits(allData?.additionalIncomes);
                setAllDirectCredits(allData?.directIncomes);
                setTotalCredits(allData?.totalDirectCredit);
                setOverDueAmount(allData?.overDueAmount);
                setBalanceSheetData(allData?.balanceData);
                setAllGameDebits(allData?.gamerelated);
                setTotalGameDebits(allData?.totalGameDebit)
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        // getBalanceSheetData();
        getTotalBalanceSheetDetails();
    }, [selectedYear])

    return (
        <>
            <div className='w-full flex-1 flex flex-col p-2 lg:p-4'>
                <div className={`flex justify-between px-0 lg:px-2 mb-4 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
                    <h2 className='text-xl whitespace-nowrap font-bold mb-4'>{t("balance-sheet")}</h2>
                    <SelectYearComponent
                        selectedYear={selectedYear}
                        setSelectedYear={setSelectedYear}
                    />
                </div>
                <div className="w-full flex flex-wrap justify-start py-4 gap-2 lg:gap-4 items-start">
                    {balanceSheetData ? balanceSheetData?.map((expense, index) => (
                        <div className='min-w-full lg:min-w-[32%]'>
                            <ExpenseCard
                                key={index}
                                expense={expense}
                                icon={cardColors[index % cardColors.length]?.icon}
                                color={cardColors[index % cardColors.length]?.bg}
                                brColor={cardColors[index % cardColors.length]?.color}
                                dynamicCategory={false}
                            />
                        </div>
                    )) : <ExpensesCardSkeleton />}
                </div>
                <div className='w-full flex flex-col items-start gap-4 lg:gap-6'>
                    <BalanceSheetTamplate
                        allDirectDebits={allDirectDebits}
                        allDirectCredits={allDirectCredits}
                        totalCredits={totalCredits}
                        totalIndirectCredits={totalIndirectCredits}
                        allIndirectCredits={allIndirectCredits}
                        totalDirectDebit={totalDirectDebit}
                        allIndirectDebits={allIndirectDebits}
                        totalIndirectDebit={totalIndirectDebit}
                        additionalDebits={additionalDebits}
                        additionalCredits={additionalCredits}
                        expensesTotal={expensesTotal}
                        incomeTotal={incomeTotal}
                        overDueAmount={overDueAmount}
                        allGameDebits={allGameDebits}
                        totalGameDebits={totalGameDebits}
                    />
                </div>
            </div>
        </>
    )
}

export default BalanceSheet
