import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Button,
  InputGroup,
  Text,
  Heading,
  useColorModeValue,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import { get } from '../../services/apis/api';
import DatePicker from 'react-datepicker';
import { CiCalendarDate } from 'react-icons/ci';
import { UserState } from '../../context/user';
import { jsPDF } from 'jspdf';

const EmployeePerformancePlan = () => {
  const [performanceData, setPerformanceData] = useState();
  const [startDate, setStartDate] = useState('');
  const [isOpen, setIsOpen] = useState(false);  // for confirmation dialog
  const [cancelRef, setCancelRef] = useState(null);  // for the cancel button in AlertDialog
  const { user } = UserState();

  const handleDateChange = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      const updatedDate = new Date(date.getFullYear(), date.getMonth(), 30);
      setStartDate(updatedDate);
    } else {
      console.log("Invalid date selected");
    }
  };

  const getPerformanceDetails = async () => {
    try {
      const res = await get(`performance/employee-data?date=${startDate}`);
      if (res.statusCode === 200) {
        setPerformanceData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPerformanceDetails();
  }, [startDate]);

  // Colors based on mode
  const tableBg = useColorModeValue('gray.50', 'gray.700');
  const sectionBg = useColorModeValue('gray.100', 'gray.800');
  const textColor = useColorModeValue('gray.700', 'gray.200');

  // Function to generate PDF
  const generatePDF = () => {
    const doc = new jsPDF();

    // Title
    doc.setFontSize(16);
    doc.text("Employee Performance Report", 20, 20);

    // Employee Info
    doc.setFontSize(12);
    doc.text(`Employee: ${user?.name}`, 20, 30);
    doc.text(`Employee Code: ${user?.employeeCode}`, 20, 35);
    doc.text(`Designation: ${user?.designation}`, 20, 40);

    // Performance Data Table
    doc.text("Performance Data:", 20, 50);
    let y = 55;
    performanceData?.performanceQue?.forEach((plan, idx) => {
      doc.text(`${plan?.questionDetails?.questionText}: ${plan?.score}`, 20, y);
      y += 5;
    });

    // Monthly Performance Score
    doc.text(`Monthly Performance Score: ${performanceData?.finalPerformance}`, 20, y + 10);

    // Save the PDF
    doc.save("performance_report.pdf");
  };

  // Function to handle confirmation and download
  const handleDownload = () => {
    setIsOpen(true);
  };

  // Close confirmation dialog
  const closeDialog = () => {
    setIsOpen(false);
  };

  // Confirm download
  const confirmDownload = () => {
    generatePDF();
    closeDialog();
  };

  return (
    <Box width="100%" p={4} bg="white" shadow="md" rounded="lg">
      {/* Header */}
      <Flex justify="space-between" align="center" mb={6} wrap={'wrap'}>
        <Heading size="lg">Performance</Heading>
        <Flex align="center" gap={2} wrap={'wrap'}>
          <InputGroup position="relative" w="full" maxW="240px">
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              placeholderText="Select Month"
              className="border rounded-md p-2 w-[240px]"
            />
            <CiCalendarDate className="absolute right-3 top-1/2 transform -translate-y-1/2 text-xl text-gray-500 pointer-events-none" />
          </InputGroup>
          <Button leftIcon={<DownloadIcon />} colorScheme="blue" onClick={handleDownload}>
            Download Report
          </Button>
        </Flex>
      </Flex>

      {/* Employee Profile Card */}
      <Box bg={sectionBg} p={3} rounded="lg" mb={6} shadow="sm">
        <Flex align="center">
          <Avatar src={user?.pic} name={user?.name} size="lg" mr={4} />
          <Box>
            <Text fontSize="lg" fontWeight="bold" color={textColor}>{user?.name}</Text>
            <Text color="gray.500">Employee Code: {user?.employeeCode}</Text>
            <Text color="gray.500">Designation: {user?.designation}</Text>
          </Box>
        </Flex>
      </Box>

      {/* Performance Data Table */}
      {performanceData ? (
        <Table variant="simple" bg={tableBg} rounded="lg" overflow="hidden" shadow="sm">
          <Thead bg="blue.600">
            <Tr>
              <Th color="white">Question</Th>
              <Th color="white">Score</Th>
            </Tr>
          </Thead>
          <Tbody>
            {performanceData?.performanceQue?.map((plan, idx) => (
              <Tr key={idx} bg={idx % 2 === 0 ? 'gray.50' : 'white'}>
                <Td>{plan?.questionDetails?.questionText}</Td>
                <Td>{plan?.score}</Td>
              </Tr>
            ))}
            <Tr bg="blue.100">
              <Td colSpan="1" fontWeight="bold">Monthly Performance Score</Td>
              <Td fontWeight="bold">{performanceData?.finalPerformance}</Td>
            </Tr>
          </Tbody>
        </Table>
      ) : (
        <Box
          bg="red.100"
          color="red.600"
          p={4}
          mt={4}
          textAlign="center"
          rounded="lg"
          shadow="sm"
        >
          No data found for the selected month.
        </Box>
      )}

      {/* Confirmation Dialog */}
      <AlertDialog isOpen={isOpen} onClose={closeDialog} leastDestructiveRef={cancelRef}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Download
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to download the performance report as a PDF?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeDialog}>
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={confirmDownload} ml={3}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default EmployeePerformancePlan;
