import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ExpensesCardSkeleton } from '../../components/skeletons/Skeletons';
import { useNavigate } from 'react-router-dom';
import { Box, Button, useDisclosure, useToast } from '@chakra-ui/react';
import { UserState } from '../../context/user';
import ExpenseCard from '../../pages/account/ExpenseCard';
import CategoryExpenseCard from './CategoryExpenseCard';


const AccordianComponent = ({
    options,
    label,
    onOpenDialogue,
    cardColors
}) => {
    const { user } = UserState();
    const toast = useToast();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const navigate = useNavigate();


    return (
        <Accordion
            style={{
                background: 'white',
                borderRadius: 10,
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0p',
                border: '1px solid transparent',
                overflow: 'hidden',
                width: '100%',
                border: '1px solid #eff3fe'
            }}
            defaultIndex={[0]} // Default open the first accordion item
            allowMultiple
        > 
            <AccordionItem>
                <>
                    <AccordionButton
                        style={{
                            background: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                            justifyContent: 'space-between',
                            borderBottom: '1px solid #eff3fe', // Border visible only when open
                        }}
                    >
                        <span className='text-xl lg:text-lg text-gray-600  font-bold leading-10'>{label}</span>
                        <div className='flex items-center gap-2 lg:gap-4'>
                            {label?.toLowerCase()?.includes('indirect') ?
                                <Button
                                    colorScheme="blue"
                                    onClick={(e) => {
                                        e.stopPropagation(); // Stop the event from propagating
                                        onOpenDialogue();
                                    }}
                                >
                                    {t("add-category")}
                                </Button>
                                : null}
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="full"
                                boxSize="40px"
                                bg="#eef2ff" // Change background color when open
                                color="#8c97f8"  // Change icon color when open
                            >
                                <AccordionIcon />
                            </Box>
                        </div>
                    </AccordionButton>
                    <AccordionPanel p={4}>
                        <div className="flex flex-wrap justify-start gap-3  w-full">
                            {options && options?.length > 0 ? (
                                options?.map((opt, index) => (
                                    <div key={index} className='min-w-full md:min-w-[49%] lg:min-w-[32%]'>
                                        <CategoryExpenseCard
                                            key={index}
                                            expense={opt}
                                            colors={cardColors[index % cardColors.length]}
                                            dynamicCategory={label?.toLowerCase()?.includes('indirect') ? true : false}
                                            isPer={label?.toLowerCase()?.includes('indirect') ? false : true}
                                            onHandleClick={() => {
                                                console.log(opt, 'opt------>');
                                                navigate(`/account/expenses/${opt?.label?.toLowerCase().replace(/\s+/g, '-')}/${opt?._id}`);
                                            }}
                                        />
                                    </div>
                                ))
                            ) : (
                                <ExpensesCardSkeleton />
                            )}
                        </div>
                    </AccordionPanel>
                </>
            </AccordionItem>
        </Accordion>
    );
};

export default AccordianComponent;
