import React, { useEffect, useState } from 'react';
import RequestBody from '../../components/employees/RequestBody';
import { get, post } from '../../services/apis/api';
import { UserState } from '../../context/user';
import { Avatar, Box, Button, Flex, Heading, IconButton, Spinner, Text, useColorModeValue } from '@chakra-ui/react';
import moment from 'moment/moment';
import { HiOutlineCalendarDays } from "react-icons/hi2";
import RequestDetailsModal from '../../components/employees/RequestDetailsModal';

const Request = () => {
    // State for managing modals and form inputs
    const { user, setUser } = UserState();
    const [isModalOpen, setModalOpen] = useState(false);
    const [requests, setRequests] = useState(null);
    const [requestType, setRequestType] = useState('');
    const [loadding, setLoadding] = useState(false);
    const [additionalFields, setAdditionalFields] = useState({});

    const tableBg = useColorModeValue('gray.50', 'gray.700');
    const sectionBg = useColorModeValue('gray.100', 'gray.800');
    const textColor = useColorModeValue('gray.700', 'gray.200');

    const getAllRequests = async () => {
        try {
            const res = await get(`request/all-employee-request/${user._id}`);
            if (res.statusCode === 200) {
                setRequests(res.data.requests)
            }
        } catch (error) {
            console.log(error);

        }
    }

    useEffect(() => {
        getAllRequests();
    }, [])


    const toggleModal = () => setModalOpen(!isModalOpen);

    const handleSubmitRequest = async () => {
        try {
            setLoadding(true);
            const body = {
                employeeId: user._id,
                requestType,
                status: user.role === 'COACH' ? "GAME-MANAGER-REVIEW" : "HR-MANAGER-REVIEW",
                details: additionalFields
            }
            const res = await post('request/employee-request', body);
            if (res.statusCode === 200) {
                setLoadding(false);
                setRequestType('');
                setAdditionalFields({});
                toggleModal();
                getAllRequests();
            }
        } catch (error) {
            setLoadding(false);
            console.log(error);
        }
    };

    if (!requests) {
        return (
            <div className='h-[60vh] w-[60vw] flex justify-center items-center'>
                <Spinner />
            </div>
        )
    }
    return (
        <div className="w-full h-full p-4">
            {/* Header */}
            <div className="flex justify-between items-center mb-8 flex-wrap">
                <Heading size="lg">My Requests</Heading>
                <Button onClick={toggleModal} colorScheme='blue'>
                    New Request
                </Button>
            </div>

            <Box bg={sectionBg} p={3} rounded="lg" mb={6} shadow="sm">
                <Flex align="center">
                    <Avatar src={user?.pic} name={user?.name} size="lg" mr={4} />
                    <Box>
                        <Text fontSize="lg" fontWeight="bold" color={textColor}>{user?.name}</Text>
                        <Text color="gray.500">Employee Code: {user?.employeeCode}</Text>
                        <Text color="gray.500">Designation: {user?.designation}</Text>
                    </Box>
                </Flex>
            </Box>


            {/* Request List */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {requests && requests?.map((request) => (
                    <div
                        key={request.id}
                        className="p-4 bg-white dark:bg-gray-800 shadow-md rounded-md transition duration-200 transform hover:scale-105"
                    >
                        <h2 className="text-xl font-semibold text-gray-700 dark:text-gray-200">
                            {request.requestType}
                        </h2>
                        <p className="text-gray-500 text-xs dark:text-gray-400 mt-2 flex items-center">
                            <span> <HiOutlineCalendarDays className='me-1 h-4 w-4' /></span> <span>{moment(request?.createdAt).format('DD MMM YYYY')}</span>
                        </p>
                        <div className="mt-4 flex justify-between items-center">
                            <span
                                className={`text-xs font-medium px-3 py-1 rounded-lg ${(request?.status === "REJECTED" || request?.status === "CANCELLED")
                                    ? 'bg-red-100 text-red-600'
                                    : request.status === 'APPROVED'
                                        ? 'bg-green-100 text-green-600'
                                        : 'bg-yellow-100 text-yellow-600'
                                    }`}
                            >
                                {request.status}
                            </span>
                            <span className="text-sm text-gray-400">
                                <RequestDetailsModal request={request} getAllRequests={getAllRequests} />
                            </span>
                        </div>
                    </div>
                ))}
            </div>

            {/* New Request Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3 p-6">
                        <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-200 mb-4">
                            New Request
                        </h2>
                        <div className="mb-4">
                            <label className="block text-gray-600 dark:text-gray-300 font-medium mb-2">
                                Request Type<span className='text-red-500'>*</span>
                            </label>
                            <select
                                value={requestType}
                                onChange={(e) => { setRequestType(e.target.value); setAdditionalFields({}) }}
                                className="w-full p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            >
                                <option value="" disabled hidden>
                                    Select Request Type
                                </option>
                                <option value="Leave Request">Leave Request</option>
                                <option value="Salary Increment Request">Salary Increment Request</option>
                                <option value="Contract Renewal Request">Contract Renewal Request</option>
                                <option value="Training Request">Training Request</option>
                                <option value="Employee Certificate Request">Employee Certificate Request</option>
                                <option value="Fixation of Salary Request">Fixation of Salary</option>
                                <option value="Authorization Letter">Authorization Letter</option>
                                <option value="Resignation Request">Resignation Request</option>
                            </select>

                        </div>

                        {/* Pass values to RequestBody */}
                        <RequestBody
                            requestType={requestType}
                            additionalFields={additionalFields}
                            setAdditionalFields={setAdditionalFields}

                        />

                        <div className="flex justify-end space-x-4 mt-4">
                            <button
                                onClick={toggleModal}
                                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-md"
                            >
                                Cancel
                            </button>
                            <button
                                disabled={loadding}
                                onClick={handleSubmitRequest}
                                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                            >
                                {loadding ? <Spinner /> : 'Submit'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Request;
