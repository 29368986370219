import { Th, Table, TableContainer, Thead, Tr, Td, Tbody, Button, useDisclosure, useToast, Box, Flex, Tooltip, IconButton } from '@chakra-ui/react';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';
import moment from 'moment/moment';
import { truncateText } from '../../services/apis/api';
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";

const TableComponent = ({
    rows,
    columns,
    setPage,
    limit,
    totalPages,
    page,
    setSkip,
    onOpenPayment,
    onConfirmDelete
}) => {
    const { user } = UserState();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { t, i18n } = useTranslation();


    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
            // console.log(page * limit);
            setSkip(page * limit); // Update skip whenever page or limit changes
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
            setSkip((page - 2) * limit);
        }
    };



    return (
        <>
            <TableContainer shadow="sm" borderRadius="lg" display="flex" flexDirection="column">
                <Table variant="simple">
                    <Thead bg="gray.50">
                        <Tr>
                            {columns?.map((column, index) => (
                                <Th key={index}>{t(column)}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {rows?.length > 0 ? (
                            rows?.map((request, index) => (
                                <Tr key={request._id} _hover={{ bg: 'gray.50' }}>
                                    <Td>{index + 1}</Td>
                                    {columns?.includes('Date-of-entry') &&
                                        <Td>{request?.date ? moment(request.date).format('DD-MM-YYYY') : ''}</Td>
                                    }
                                    <Td>{request.amount?.toFixed(2) || 'N/A'}</Td>
                                    {(columns?.includes('Transection Type') || columns?.includes('transection-type')) && <Td>{request?.transactionType || 'N/A'}</Td>}
                                    {columns?.includes('payment-method') &&
                                        <Td>{request?.paymentMethod || 'N/A'}</Td>
                                    }
                                    {columns?.includes('payment-id') &&
                                        <Td>{request?.paymentId || 'N/A'}</Td>
                                    }
                                    {(columns?.includes('source-of-income') || columns?.includes('source')) && <Td>{request?.source || 'N/A'}</Td>}
                                    <Td>{request?.createdBy?.name || request?.requestedBy?.name || 'N/A'}</Td>
                                    {columns?.includes('created-at') &&
                                        <Td>{request?.createdAt ? moment(request?.createdAt).format('DD-MM-YYYY') : ''}</Td>
                                    }
                                    {columns?.includes('description') &&
                                        <Tooltip label={request?.description || 'N/A'}>
                                            <Td>{request?.description ? truncateText(request?.description) : 'N/A'}</Td>
                                        </Tooltip>
                                    }
                                    {columns?.includes('actions') &&
                                        <Td>
                                            <IconButton
                                                mr={3}
                                                onClick={() => {
                                                    if (onOpenPayment) {
                                                        onOpenPayment(request);
                                                    }
                                                }}
                                                aria-label="Search database">
                                                <FaRegEdit />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    if (onConfirmDelete) {
                                                        onConfirmDelete(request);
                                                    }
                                                }}
                                                aria-label="Search database">
                                                <MdDeleteOutline />
                                            </IconButton>
                                        </Td>
                                    }

                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Td colSpan="5" textAlign="center">
                                    {t('no-data-found')}
                                </Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </TableContainer>
            {/* Pagination Controls */}
            <Flex justify="space-between" align="center" mt={4}>
                <Button onClick={handlePrevPage} isDisabled={page === 1}>
                    Previous
                </Button>
                <Box>
                    Page {page} of {totalPages}
                </Box>
                <Button onClick={handleNextPage} isDisabled={page === totalPages}>
                    Next
                </Button>
            </Flex>
        </>
    );
};

export default TableComponent