import React, { useEffect, useState } from 'react';
import { get, post } from '../../services/apis/api'; // Assuming 'post' is for API updates
import { Button, Table, Thead, Tbody, Tr, Th, Td, useToast, InputGroup, InputLeftElement, Input } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

const TeamAttendance = () => {
    const toast = useToast();
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); // State for search input
    const [selectedDate, setSelectedDate] = useState(''); // State for selected date

    // Fetch attendance list with search and date filter
    const getTeamAttendanceList = async () => {
        try {
            const res = await get(`attendance/list/check-in-out?name=${searchTerm}&date=${selectedDate}`);
            console.log('API Response:', res);
            setList(res?.data?.data || []);
        } catch (error) {
            console.error('Error fetching attendance list:', error);
        } finally {
            setLoading(false);
        }
    };

    // Approve attendance
    const approveAttendance = async (attendanceId, _status) => {
        setIsLoading(true);
        try {
            const res = await post(`attendance/approve-check-in-out`, { dailyAttendanceId: attendanceId, status: _status });
            setIsLoading(false);
            if (res.statusCode === 200) {
                getTeamAttendanceList();
                toast({
                    title: 'Successful!',
                    description: _status != 'APPROVED' ? 'REJECTED Successfully' : 'Approved Successfully',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            setIsLoading(false);
            toast({
                title: 'Error !',
                description: 'Some error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            console.error('Error approving attendance:', error);
        }
    };

    useEffect(() => {
        getTeamAttendanceList();
    }, [searchTerm, selectedDate]);

    return (
        <div className="p-6">
            <div className="flex justify-between items-center">
                <div>
                    <h1 className="text-xl font-bold mb-4">Team Attendance</h1>
                </div>
                <div className="flex items-center space-x-2">
                    <InputGroup mr={2} mb={{ base: 2, md: 0 }} w={{ base: "100%", md: "" }}>
                        <InputLeftElement pointerEvents="none">
                            <SearchIcon color="gray.300" />
                        </InputLeftElement>
                        <Input
                            type="text"
                            placeholder="Search by Name"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputGroup>
                    <Input
                        id="date"
                        name="date"
                        type="date"
                        placeholder="Select Date"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                    />
                </div>
            </div>

            {loading ? (
                <p>Loading...</p>
            ) : list.length === 0 ? (
                <p>No attendance records found.</p>
            ) : (
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>User Name</Th>
                            <Th>Check-In</Th>
                            <Th>Check-Out</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {list.map((record) => (
                            <Tr key={record._id}>
                                <Td>{record.employeeId?.name || 'N/A'}</Td>
                                <Td>
                                    {record.checkInTime
                                        ? new Date(record.checkInTime).toLocaleTimeString()
                                        : 'Not Checked In'}
                                </Td>
                                <Td>
                                    {record.checkOutTime
                                        ? new Date(record.checkOutTime).toLocaleTimeString()
                                        : 'Not Checked Out'}
                                </Td>
                                <Td>
                                    {record?.approvedStatus != 'PENDING' ? (
                                        <h2 className="text-green-500">{record?.approvedStatus}</h2>
                                    ) : (
                                        <>
                                            <Button
                                                colorScheme="blue"
                                                size="sm"
                                                isLoading={isLoading}
                                                onClick={() => approveAttendance(record?._id, 'APPROVED')}
                                            // isDisabled={!record?.checkInTime || !record?.checkOutTime || record?.approved}
                                            >
                                                Approve
                                            </Button>
                                            <Button
                                                colorScheme="red"
                                                size="sm"
                                                ml={3}
                                                isLoading={isLoading}
                                                onClick={() => approveAttendance(record?._id, 'REJECTED')}
                                            // isDisabled={!record?.checkInTime || !record?.checkOutTime || record?.approvedStatus == 'REJECTED'}
                                            >
                                                Reject
                                            </Button>
                                        </>
                                    )}
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}
        </div>
    );
};

export default TeamAttendance;
