import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Grid,
    GridItem,
    Text,
    Badge,
    Button,
    Divider,
    Progress,
    Table,
    Tbody,
    Tr,
    Td,
    Thead,
    Th,
    Icon,
} from '@chakra-ui/react';
import { DownloadIcon, CheckCircleIcon, TimeIcon, CalendarIcon } from '@chakra-ui/icons';
import { UserState } from '../../context/user';
import { get } from '../../services/apis/api';

const EmployeeDashboard = () => {
    const { user } = UserState();
    const [dashboardInfo, setDashboardInfo] = useState();
    const getEmployeeDashBoardInfo = async () => {
        try {
            const res = await get('reports/employee-dashboard');
            if (res.statusCode === 200) {
                console.log(res.data.data);
                setDashboardInfo(res.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getEmployeeDashBoardInfo();
    }, []);

    const payroll = {
        grossSalary: dashboardInfo?.payroll?.salaryBreakdown?.grossPay?.toFixed(2),
        basicPay: dashboardInfo?.payroll?.salaryBreakdown?.basicSalary?.toFixed(2),
        allowances: dashboardInfo?.payroll?.salaryBreakdown?.specialAllowance?.toFixed(2),
        deductions: dashboardInfo?.payroll?.deductions?.totalDeductions?.toFixed(2),
        overtimePay: 0,
        bonus: dashboardInfo?.payroll?.salaryBreakdown?.bonus?.toFixed(2),
        netPay: dashboardInfo?.payroll?.salaryBreakdown?.netPay?.toFixed(2),
        paymentStatus: dashboardInfo?.payroll?.paymentStatus,
    };

    const attendance = {
        presentDays: dashboardInfo?.attendance?.payableDays,
        absentDays: dashboardInfo?.attendance?.totalAbsent,
        overtimeHours: dashboardInfo?.attendance?.overTimeHours,
        paidLeave: dashboardInfo?.attendance?.totalLeave,
        sickLeave: dashboardInfo?.attendance?.sickLeave,
    };

    const performance = {
        score: dashboardInfo?.performance?.finalPerformance,
        lastReview: 'Good performance with excellent team collaboration.',
    };

    const requests = dashboardInfo?.request;

    return (
        <Box width="100%" p={{ base: 4, md: 6 }}>
            <Text fontSize={{ base: "xl", md: "2xl" }} fontWeight="bold" mb={6}>
                <span> Welcome, <span className="text-blue-600">{user.name}</span></span>
            </Text>

            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
                {/* Last Month Payroll Summary */}
                <GridItem p={6} shadow="sm" borderWidth="1px" borderRadius="md" bg="white">
                    <Text fontSize="lg" fontWeight="bold" mb={4}>
                        Last Month Payroll
                    </Text>
                    <Divider mb={4} />
                    <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={{ base: 2, md: 4 }}>
                        <Flex justify="space-between">
                            <Text fontWeight="semibold">Gross Salary</Text>
                            <Text>{payroll.grossSalary}</Text>
                        </Flex>
                        <Flex justify="space-between">
                            <Text fontWeight="semibold">Net Pay</Text>
                            <Text color="blue.600" fontWeight="bold">{payroll.netPay}</Text>
                        </Flex>
                        <Flex justify="space-between">
                            <Text fontWeight="semibold">Payment Status</Text>
                            <Badge colorScheme={payroll.paymentStatus === 'Paid' ? 'green' : 'red'} className='h-6 flex justify-center items-center p-1 rounded-md'>
                                {payroll.paymentStatus}
                            </Badge>
                        </Flex>
                    </Box>
        
                </GridItem>

                {/* Attendance Summary */}
                <GridItem p={6} shadow="md" borderWidth="1px" borderRadius="md" bg="white">
                    <Flex align="center" mb={4}>
                        <CalendarIcon boxSize={5} mr={3} color="blue.500" />
                        <Text fontSize="lg" fontWeight="bold">Attendance Summary</Text>
                    </Flex>
                    <Divider mb={4} />
                    <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}>
                        <Flex justify="space-between">
                            <Text>Present Days</Text>
                            <Text>{attendance.presentDays}</Text>
                        </Flex>
                        <Flex justify="space-between">
                            <Text>Absent Days</Text>
                            <Text>{attendance.absentDays}</Text>
                        </Flex>
                        <Flex justify="space-between">
                            <Text>Overtime Hours</Text>
                            <Text>{attendance.overtimeHours}</Text>
                        </Flex>
                    </Box>
                </GridItem>

                {/* Performance Summary */}
                <GridItem p={6} shadow="md" borderWidth="1px" borderRadius="md" bg="white">
                    <Flex align="center" mb={4}>
                        <CheckCircleIcon boxSize={5} mr={3} color="green.500" />
                        <Text fontSize="lg" fontWeight="bold">Performance Summary</Text>
                    </Flex>
                    <Divider mb={4} />
                    <Box>
                        <Text fontWeight="semibold">Monthly Performance Score</Text>
                        <Progress value={performance.score * 10} colorScheme="blue" size="lg" mt={2} borderRadius="md" />
                        <Text fontSize="sm" color="gray.500" mt={2}>
                            {performance.score}/10
                        </Text>
                    </Box>
                </GridItem>

                {/* Recent Requests */}
                <GridItem colSpan={{ base: 1, md: 2 }} p={6} shadow="sm" borderWidth="1px" borderRadius="md" bg="white">
                    <Text fontSize="lg" fontWeight="bold" mb={4}>
                        Recent Requests
                    </Text>
                    <Divider mb={4} />
                    <Table variant="responsive" size="sm" overflow={'scroll'}>
                        <Thead>
                            <Tr>
                                <Th>Request Type</Th>
                                <Th>Status</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {requests && requests.map((request, index) => (
                                <Tr key={index}>
                                    <Td>{request.requestType}</Td>
                                    <Td>
                                        <Badge colorScheme={request.status === 'Approved' ? 'green' : request.status === 'Pending' ? 'yellow' : 'red'}>
                                            {request.status}
                                        </Badge>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </GridItem>
            </Grid>
        </Box>
    );
};

export default EmployeeDashboard;
