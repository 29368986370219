import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    Input,
    FormLabel,
    Button,
    Select,
    useToast,
    Textarea
} from '@chakra-ui/react'
import { set } from 'lodash';

import React from 'react'
import { useTranslation } from 'react-i18next';

const _methodType = [
    'cash',
    'online',
    'check'
]

function AddAcademyIncome({ isOpen, category, onClose, selectedSubs, setSelectedSubs, allPendingSubs, onReset, formData, onHandleChange, isLoading, onHandleSubmit }) {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';


    return (
        <>
            {/* <Button >Open Modal</Button> */}
            <Modal
                // initialFocusRef={initialRef}
                // finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add New Earning</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody mt={4}>
                        <FormControl>
                            <FormLabel>Date <span className='text-red-600'>*</span></FormLabel>
                            <Input
                                type='date'
                                value={formData?.entryDate}
                                onChange={(e) => {
                                    const selectedDate = new Date(e.target.value);
                                    const today = new Date();
                                    // Check if the selected date is greater than today
                                    if (selectedDate <= today) {
                                        onHandleChange('entryDate', e.target.value);
                                    } else {
                                        alert("The selected date cannot be greater than today.");
                                    }
                                }}
                                placeholder='Date'
                            />
                        </FormControl>
                        {allPendingSubs && allPendingSubs?.length > 0 ?
                            <FormControl mt={4}>
                                <FormLabel>Select Player <span className='text-red-600'>*</span></FormLabel>
                                <Select
                                    placeholder={t('select-player')}
                                    textAlign={isArabic ? 'right' : 'left'}
                                    bg="#f9fafb"
                                    borderColor="gray.300"
                                    value={selectedSubs}
                                    onChange={(e) => {
                                        const selectedValue = JSON.parse(e.target.value); // Parse JSON string back to object
                                        // console.log(selectedSubs,'ssss');
                                        
                                        setSelectedSubs(selectedValue); // Update selected subscription
                                        onHandleChange('amount', selectedValue?.courseId?.price); // Call the function with course name
                                    }}
                                >
                                    {allPendingSubs?.map((subs) => (
                                        <option key={subs._id} value={JSON.stringify(subs)}>
                                            {t(subs?.playerId?.name)}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                            : null}

                        <FormControl mt={4}>
                            <FormLabel>Amount <span className='text-red-600'>*</span></FormLabel>
                            <Input
                                value={formData?.amount}
                                type='number'
                                onChange={(e) => {
                                    onHandleChange('amount', e.target.value)
                                }}
                                placeholder='Amount'
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>{t('select-payment-method')}<span className='text-red-600'>*</span></FormLabel>
                            <Select
                                placeholder={t('select-payment-method')}
                                textAlign={isArabic ? 'right' : 'left'}
                                bg="#f9fafb"
                                borderColor="gray.300"
                                value={formData?.paymentMethod}
                                onChange={(e) => {
                                    onHandleChange('paymentMethod', e?.target?.value); // Call the function with course name
                                }}
                            >
                                {_methodType?.map((method) => (
                                    <option key={method} value={method}>
                                        {t(method)}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                        {formData.paymentMethod != 'cash' && (
                            <FormControl mt={4}>
                                <FormLabel>{formData.paymentMethod === 'check' ? t('check-number') : t('transaction-id')}<span className='text-red-600'>*</span></FormLabel>
                                <Input
                                    type="text"
                                    placeholder={formData.paymentMethod === 'check' ? t('enter-check-number') : t('enter-transaction-id')}
                                    value={formData?.paymentId}
                                    onChange={(e) => onHandleChange('paymentId', e?.target?.value)} // Update paymentId
                                />
                            </FormControl>
                        )}
                        <FormControl mt={4}>
                            <FormLabel>Add Note</FormLabel>
                            <Textarea
                                value={formData?.description}
                                onChange={(e) => {
                                    onHandleChange('description', e.target.value)
                                }}
                                placeholder='Entry Note'
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={4}
                            onClick={onReset}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={onHandleSubmit}
                            // onClick={handleSave}
                            colorScheme='blue'
                            isLoading={isLoading}
                            isDisabled={formData?.entryDate && formData?.amount ? false : true}
                        >
                            Add
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    )
}

export default AddAcademyIncome