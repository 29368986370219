import React, { useEffect, useState } from 'react'
import AddPerformance from '../../components/hr/dialogBoxes/AddPerformance';
import { get } from '../../services/apis/api';
import { UserState } from '../../context/user';
import { Box, Image, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const TeamPerformance = () => {
    const {user} = UserState();
    const [teamMembers, setTeamMembers] = useState(null);
    const getReportingHeadMembers = async()=>{
        try {
            const res = await get(`performance/reporting-head-members?reportingHead=${user._id}`);
            console.log(res);
            setTeamMembers(res.data.data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getReportingHeadMembers();
    }, []);

    if(teamMembers===null){
        return (
            <div className='flex !w-full h-[75vh] items-center justify-center'>
                <Spinner/>
            </div>
        )
    }
    
    return (
        <div>
            <div className='flex justify-between items-center'>
                <div>
                    <h1 className="text-xl font-bold mb-4">Team Performance</h1>
                </div>
                <div>
                    <AddPerformance getAllEmployeePerformance={()=>{}} isEdit={false} />
                </div>
            </div>

            <div className='mt-4'>
                <Table className="w-full text-sm text-left text-gray-500">
                    <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <Tr>
                            <Th>User</Th>
                            <Th>EmployeeCode</Th>
                            <Th>Designation</Th>
                            <Th>mobile</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {teamMembers.map((member) => (
                            <Tr key={member._id}>
                                    <Link to={`/employee/team-performance/emp/${member._id}`}>
                                        <Td className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                                            <Image className="w-10 h-10 rounded-full" src={member.pic} alt="Employee image" />
                                            <Box ml={3}>
                                                <Text className="text-base font-semibold">{member.name}</Text>
                                                <Text className="font-normal text-gray-500">{member.email}</Text>
                                            </Box>
                                        </Td>
                                    </Link>
                                    <Td>{member?.employeeCode || 'N/A'}</Td>
                                    <Td>{member?.designation || 'N/A'}</Td>
                                    <Td>{member?.mobile || 'N/A'}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </div>

        </div>
    )
}

export default TeamPerformance;
