import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';
import { ArrowForwardIcon, InfoIcon } from '@chakra-ui/icons';
import { get, post } from '../../services/apis/api';
import { ExpensesCardSkeleton } from '../../components/skeletons/Skeletons';
import { useNavigate } from 'react-router-dom';
import PastTransactions from '../../components/account/PastTransactions';
import { Box, Button, Skeleton, useDisclosure, useToast } from '@chakra-ui/react';
import AddExpenseCategoryModel from '../../components/account/AddExpenseCategory';
import AccordianComponent from '../../components/account/AccordianComponent';
import { FaDollarSign } from 'react-icons/fa';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';
import ExpenseCard from './ExpenseCard';
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { FaUserFriends } from "react-icons/fa";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { IoGameControllerOutline } from "react-icons/io5";
import { IoTrophyOutline } from "react-icons/io5";

import { HiOutlineFolder } from 'react-icons/hi'; // Heroicons
import { FaChartPie } from 'react-icons/fa'; // Font Awesome
import { FiSettings } from 'react-icons/fi'; // Feather Icons
import { AiOutlineTags } from 'react-icons/ai'; // Ant Design Icons
import { FaClipboardList } from 'react-icons/fa'; // Font Awesome


// CATEGORY CARD COLORS 
const cardColors = [
    // '#683fe9',
    '#f4edff', // Light cyan
    '#E6EDFF', // Light red
    '#E5F1FF', // Light green
    '#fff9c4', // Light blue,
    '#ffe0b2',
    '#f0f4c3'
]

// ADD CATEGORY FORM DATA 
const _expenseFormData = {
    name: '',
    description: ''
}

// // CATEGORY CARD COLORS 
// const cardColors = [
//     // '#683fe9',
//     '#f4edff', // Light cyan
//     '#E6EDFF', // Light red
//     '#E5F1FF', // Light green
//     '#fff9c4', // Light blue,
//     '#ffe0b2',
//     '#f0f4c3'
// ]

const gameColors = [
    {
        bg: '#f4f0ff', // Soft pastel blue
        colo: '#7c3aed',
        brder: '#7dd3fc', // Sky blue
        icon: '#e32952',
        icon: <IoGameControllerOutline color='#7c3aed' />
    },
    {
        bg: '#eaf1ff', // Very light pink
        color: '#5249e5',
        brder: '#f472b6', // Rose pink
        icon: '#e32952',
        icon: <FaUserFriends color="#5249e5" />
    },
    {
        bg: '#eaf5fe', // Light pastel gray-blue
        color: '#5e8cf0',
        brder: '#64748b', // Muted slate blue
        icon: '#e32952',
        icon: <IoTrophyOutline color='#5e8cf0' />
    },
];

const directExpensesColors = [
    // {
    //     bgColor: "#f0f9ff", // Light blue shade
    //     color: "#0369a1",   // Deep blue for contrast
    // },
    {
        bg: "#ffeaeb", // Light green shade
        color: "#065f46",   // Dark green for contrast
        iconColor: '#e32952',
        icon: <FaUserFriends color='#e32952' />
    },
    {
        bg: "#fff5e1", // Light yellow shade
        color: "#854d0e",   // Rich brown for text
        iconColor: '#f29c6d',
        icon: <FaMoneyCheckAlt color="#f29c6d" />
    },
    {
        bg: "#e2fcef", // Light red shade
        color: "#991b1b",   // Dark red for text
        iconColor: '#059669',
        icon: <HiOutlineBuildingOffice2 color='#059669' />
    },
];

const indirectExpenseColors = [
    {
        bg: "#eef2ff", // Light lavender
        color: "#4338ca",   // Deep indigo
        iconColor: '#e32952',
        icon: <FaChartPie color='#c6d4ff ' />
    },
    {
        bg: "#fef9c3", // Pale yellow
        color: "#854d0e",   // Rich brown
        iconColor: '#e32952',
        icon: <FaChartPie color='#e0d200' />
    },
    {
        bg: "#f0fdf4", // Mint green
        color: "#15803d",   // Forest green
        iconColor: '#e32952',
        icon: <FaChartPie color='#c1e7d2' />
    },
    {
        bg: "#fff7ed", // Soft peach
        color: "#c2410c",   // Burnt orange
        iconColor: '#e32952',
        icon: <FaChartPie color='#f4e2c0' />
    },
];

const Expenses = () => {
    const { user } = UserState();
    const toast = useToast();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [isLoading, setIsLoading] = useState(false);
    const [expenseFormData, setExpenseFormData] = useState({ ..._expenseFormData });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [expensesData, setExpensesData] = React.useState(null);
    const [gamesExpensesData, setGamesExpensesData] = React.useState(null);
    const [directExpensesData, setdirectExpensesData] = React.useState(null);
    const [totalExpensesData, setTotalExpensesData] = React.useState(null);
    const [additionalExpensesData, setAdditionalExpensesData] = React.useState(null);
    const [expenseCategories, setExoenseCategories] = React.useState(null);
    const navigate = useNavigate();


    // GET ALL EXEPENSE DATA 
    const getTotalExpenses = async () => {
        try {
            // console.log('data');
            const response = await get(`expenses/get-total`)
            if (response?.statusCode == 200) {
                // console.log(response, 'response');
                setExpensesData(response?.data?.data);
                setdirectExpensesData(response?.data?.data?.directExpenses);
                setGamesExpensesData(response?.data?.data?.gamerelated);
                setTotalExpensesData(response?.data?.data?.total);
                setAdditionalExpensesData(response?.data?.data?.additional);
                setExoenseCategories(response?.data?.data?.indirectExpenses);
            } else {
                setExpensesData([]);
            }
        } catch (e) {
            setExpensesData([]);
            console.log(`ERROR WHILE FETCHING DATA:${e}`);
        }
    }

    useEffect(() => {
        getTotalExpenses();
    }, [])

    // CREATE A NEW EXPENSE CATEGORY 
    const addNewCategory = async () => {
        try {
            setIsLoading(true);
            const res = await post(`expense-category/add`, expenseFormData)
            setIsLoading(false);
            if (res.statusCode === 201 || res.statusCode === 200) {
                getTotalExpenses();
                toast({
                    title: 'Success!',
                    description: res.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                setExpenseFormData({ ..._expenseFormData });
                onClose();
            }
        } catch (error) {
            setIsLoading(false);
            toast({
                title: 'Error!',
                description: error.response.data.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            console.log(`ERROR WHILE CREATING CATEGORY: ${error}`);
        }
    };

    const ReyalIcon = () => (
        <div className='p-1 rounded-md bg-white text-black'>
            ریال
        </div>
    )

    return (
        <>
            <div className='w-full flex-1 flex flex-col p-2 lg:p-4'>
                <div className={`flex justify-between px-0 lg:px-2 mb-4 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
                    <h2 className='text-xl lg:text-2xl whitespace-nowrap font-bold mb-4'>{t("expenses")}</h2>
                    <AddExpenseCategoryModel
                        isOpen={isOpen}
                        onClose={onClose}
                        expenseFormData={expenseFormData}
                        onHandleChange={(field, value) => {
                            setExpenseFormData((prevData) => ({
                                ...prevData,
                                [field]: value
                            }));
                        }}
                        isLoading={isLoading}
                        onReset={() => {
                            setExpenseFormData({ ..._expenseFormData });
                            onClose();
                        }}
                        onHandleSubmit={() => {
                            addNewCategory();
                        }}
                    />
                </div>
                <div className='w-full flex  gap-3 lg:gap-4'>
                    {totalExpensesData ?
                        <div className='min-w-full lg:min-w-[32%]'>
                            <ExpenseCard
                                expense={totalExpensesData}
                                color={"#fef2f2"}
                                brColor={"#ef4444"}
                                icon={<ReyalIcon />}
                            />
                        </div>
                        :
                        <Skeleton
                            height={150}
                            width={'30%'}
                            isLoaded={false}
                            fadeDuration={1}
                        />
                    }
                    {additionalExpensesData ?
                        <div className='min-w-full lg:min-w-[32%]'>
                            <ExpenseCard
                                expense={additionalExpensesData}
                                color={"#faf5ff"}
                                brColor={"#a855f7"}
                                icon={<ReyalIcon />}
                            />
                        </div>
                        :
                        <Skeleton
                            height={150}
                            width={'30%'}
                            isLoaded={false}
                            fadeDuration={1}
                        />
                    }
                </div>

                <div className="w-full flex flex-col justify-start py-4 gap-2 lg:gap-4 items-start mb-4">
                    <AccordianComponent
                        options={directExpensesData || []}
                        label="Direct Expenses"
                        cardColors={directExpensesColors}
                    />
                    <AccordianComponent
                        options={gamesExpensesData || []}
                        label="Game Expenses"
                        cardColors={gameColors}
                    />
                    <AccordianComponent
                        options={expenseCategories || []}
                        label="InDirect Expenses"
                        onOpenDialogue={onOpen}
                        cardColors={indirectExpenseColors}
                    />
                </div>

                <PastTransactions
                    txnType={'DR'} 
                />
            </div>
        </>
    )
}

export default Expenses
