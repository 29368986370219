import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Avatar,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  InputGroup,
  Text,
  useToast,
  VStack,
  HStack,
  Heading,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import DatePicker from 'react-datepicker';
import { CiCalendarDate } from 'react-icons/ci';
import { get } from '../../services/apis/api';
import { UserState } from '../../context/user';
import { jsPDF } from 'jspdf'; // Import jsPDF for PDF generation
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EmployeeAttendance = () => {
  const { user } = UserState();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  // Initialize state with the current date set to the 30th of the current month
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 30);
  });
  const [attendanceData, setAttendanceDate] = useState(null);
  const [lastAction, setLAstAction] = useState('');
  const [lastestAttendance, setLastestAttendance] = useState(null);
  const [isLoading, setIsLoading] = React.useState(false)
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure(); // Chakra UI modal hooks

  const sectionBg = useColorModeValue('gray.100', 'gray.800');
  const textColor = useColorModeValue('gray.700', 'gray.200');

  // HANDLE DATE CHANGE FUNCTION 
  const handleDateChange = (date) => {
    try {
      if (date instanceof Date && !isNaN(date)) {
        const updatedDate = new Date(date.getFullYear(), date.getMonth(), 30);
        setStartDate(updatedDate);
      } else {
        console.log("Invalid date selected");
      }
    } catch (e) {
      console.log(`ERROR WHILE SETTING DATE ${e}`);
    }
  };

  // FETCH ATTANDANCE DETAILS 
  const getAttendanceDetails = async (startDate) => {
    try {
      const res = await get(`attendance/employee?date=${startDate}&employeeId=${user?._id}`);
      if (res.statusCode === 200) {
        const __data = res?.data?.data;
        setAttendanceDate(__data);
        if (__data?.dayData?.length > 0) {
          let lastAttendance = __data?.dayData[__data?.dayData?.length - 1];
          setLastestAttendance(lastAttendance)
          let _lastAction = lastAttendance?.checkOutTime ? 'chack-out' : 'check-in';
          setLAstAction(_lastAction)
        } else {
          console.log('No attendance data available.');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAttendanceDetails(startDate);
  }, [startDate]);

  // HANDLE CHECK IN FUNCTION 
  const handleCheckIn = async () => {
    setIsLoading(true);
    try {
      const res = await get('attendance/check-in');
      setIsLoading(false);
      if (res.statusCode === 200) {
        getAttendanceDetails(startDate);
        toast({
          title: 'Successful!',
          description: 'Check-in Successful',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        title: 'Error!',
        description: error?.response?.data?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // HANDLE CHECK OUT FUNCTION 
  const handleCheckOut = async () => {
    setIsLoading(true);
    try {
      const res = await get(`attendance/check-out?attendance_id=${lastestAttendance?._id}`);
      setIsLoading(false);
      if (res.statusCode === 200) {
        getAttendanceDetails(startDate);
        toast({
          title: 'Successful!',
          description: 'Check-out Successful',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        title: 'Error!',
        description: error?.response?.data?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // FUNCTION TO DOWNLOAD THE REPORT AS PDF
  const downloadReport = () => {
    setIsLoading(true);
    try {
      const doc = new jsPDF();

      // Set title of the report
      doc.setFontSize(18);
      doc.text('Attendance Report', 14, 20);

      // Add user details to the PDF
      doc.setFontSize(12);
      doc.text(`Name: ${user?.name}`, 14, 30);
      doc.text(`Employee Code: ${user?.employeeCode}`, 14, 40);
      doc.text(`Designation: ${user?.designation}`, 14, 50);

      // Add attendance summary to the PDF
      if (attendanceData?.monthData) {
        doc.text(`Total Paid Days: ${attendanceData?.monthData?.paidDays}`, 14, 60);
        doc.text(`Total Payable Days: ${attendanceData?.monthData?.payableDays}`, 14, 70);
        doc.text(`Total Leave Days: ${attendanceData?.monthData?.totalLeave}`, 14, 80);
        doc.text(`Total Overtime Hours: ${attendanceData?.monthData?.overTimeHours}`, 14, 90);
        doc.text(`Total Absent Days: ${attendanceData?.monthData?.totalAbsent}`, 14, 100);
      }

      // Add daily attendance table to the PDF
      let yPosition = 110;
      doc.text('Date', 14, yPosition);
      doc.text('Status', 100, yPosition);

      attendanceData?.dayData?.forEach((record, index) => {
        yPosition += 10;
        doc.text(record.date, 14, yPosition);
        doc.text(record.status, 100, yPosition);
      });

      // Save the PDF
      doc.save('attendance_report.pdf');
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(`ERROR WHILE DOWNLODING ${e}`);
    }
  };

  return (
    <Box width="100%" p={4}>
      <VStack spacing={6} align="stretch">
        <Flex justify="space-between" align="center" wrap="wrap">
          <Heading size="lg">My Attendance</Heading>
          <HStack spacing={4} align="center" mt={{ base: 4, md: 0 }} className='flex flex-wrap'>
            <InputGroup position="relative" w="full" maxW="240px">
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                placeholderText="Select Month"
                className="border rounded-md p-2 w-[240px]"
              />
              <CiCalendarDate className="absolute right-3 top-1/2 transform -translate-y-1/2 text-xl text-gray-500 pointer-events-none" />
            </InputGroup>

            <Button
              colorScheme="green"
              onClick={handleCheckIn}
              isLoading={isLoading}
              isDisabled={lastAction == 'check-in' ? true : false}
            >
              Check In
            </Button>
            <Button
              isDisabled={lastAction == 'chack-out' ? true : false}
              colorScheme="green"
              isLoading={isLoading}
              onClick={handleCheckOut}
            >
              Check Out
            </Button>
            <Button
              leftIcon={<DownloadIcon />}
              colorScheme="gray"
              onClick={onOpen}
            >
              Download Report
            </Button>
          </HStack>
        </Flex>

        <Box bg={sectionBg} p={3} rounded="lg" shadow="sm">
          <Flex align="center">
            <Avatar src={user?.pic} name={user?.name} size="lg" mr={4} />
            <Box>
              <Text fontSize="lg" fontWeight="bold" color={textColor}>{user?.name}</Text>
              <Text color="gray.500">Employee Code: {user?.employeeCode}</Text>
              <Text color="gray.500">Designation: {user?.designation}</Text>
            </Box>
          </Flex>
        </Box>

          <VStack spacing={6} align="stretch">

        {attendanceData && attendanceData.monthData && (
            <Box p={4} bg="gray.50" borderRadius="md" boxShadow="sm">
              <Heading size="sm" mb={2}>
                Attendance Summary
              </Heading>
              <Flex justify="space-between" flexWrap="wrap">
                <VStack align="start">
                  <Text>Total Paid Days: {attendanceData?.monthData?.paidDays}</Text>
                  <Text>Total Payable Days: {attendanceData?.monthData?.payableDays}</Text>
                  <Text>Total Leave Days: {attendanceData?.monthData?.totalLeave}</Text>
                </VStack>
                <VStack align="start">
                  <Text>Total Overtime Hours: {attendanceData?.monthData?.overTimeHours}</Text>
                  <Text>Total Absent Days: {attendanceData?.monthData?.totalAbsent}</Text>
                </VStack>
              </Flex>
            </Box>
         )
        }
            <Box overflowX="auto" borderRadius="md" boxShadow="sm" bg="gray.50">
              <Table variant="striped" colorScheme="gray" size="sm">
                <Thead>
                  <Tr>
                    <Th>Date</Th>
                    <Th>Time</Th>
                    <Th>Type</Th>
                    <Th>Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {attendanceData?.dayData && attendanceData?.dayData?.length > 0 ? (
                    attendanceData?.dayData?.map((record, index) => (
                      <>
                        <Tr key={index}>
                          {/* {index} */}
                          <Td>
                            {record?.checkInTime
                              ? new Date(record.checkInTime).toLocaleDateString("en-US") // MM/DD/YYYY format
                              : "N/A"}
                          </Td>
                          <Td>
                            {record?.checkInTime
                              ? new Date(record.checkInTime).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })
                              : "N/A"}
                          </Td>
                          <Td>{t('check-in')}</Td>
                          <Td>{record?.approvedStatus}</Td>
                        </Tr>
                        {record?.checkOutTime ?
                          <Tr key={index}>
                            <Td>
                              {record?.checkOutTime
                                ? new Date(record.checkOutTime).toLocaleDateString("en-US") // MM/DD/YYYY format
                                : "N/A"}
                            </Td>
                            <Td>
                              {record?.checkOutTime
                                ? new Date(record?.checkOutTime).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })
                                : "N/A"}
                            </Td>
                            <Td>{t('check-out')}</Td>
                            <Td>{record?.approvedStatus}</Td>
                          </Tr>
                          : null}
                      </>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan="2">No data available</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Box>
          </VStack>
       
      </VStack>

      {/* Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Download Attendance Report</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to download the attendance report?</Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              colorScheme="blue"
              onClick={() => {
                downloadReport();
                onClose();
              }}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default EmployeeAttendance;
