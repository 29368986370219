import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    Input,
    FormLabel,
    Button,
    Select,
    useToast,
    Textarea
} from '@chakra-ui/react'
import { set } from 'lodash';

import React from 'react'
import { BiImage } from 'react-icons/bi'
import { postFormData } from '../../services/apis/api';

function AddCategoryModel({ isOpen, onClose, onReset,categoryFormData, onHandleChange, isLoading, onHandleSubmit }) {
    const imgRef = React.useRef(null);

    // Function to handle file selection
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Create a URL for the selected image
            const imageURL = URL.createObjectURL(file);
            onHandleChange('selectedImg', imageURL);
            onHandleChange('file', file);
        }
    };

    return (
        <>
            {/* <Button >Open Modal</Button> */}
            <Modal
                // initialFocusRef={initialRef}
                // finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create New Income Category</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>Category Name</FormLabel>
                            <Input
                                value={categoryFormData?.name}
                                onChange={(e) => {
                                    onHandleChange('name', e.target.value)
                                }}
                                placeholder='Category Name'
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Category Description</FormLabel>
                            <Textarea
                                value={categoryFormData?.description}
                                onChange={(e) => {
                                    onHandleChange('description', e.target.value)
                                }}
                                placeholder='Category Description'
                            />
                        </FormControl>
                        {/* <FormControl mt={4}>
                            <FormLabel>Category Mode</FormLabel>
                            <Select
                                value={categoryFormData?.mode}
                                onChange={(e) => {
                                    onHandleChange('mode', e.target.value)
                                }}
                                placeholder='Select Mode'
                            >
                                <option value='online'>Online</option>
                                <option value='offline'>Offline </option>
                            </Select>
                        </FormControl> */}
                        {/* <FormControl mt={4}>
                            <FormLabel>Select Category Img</FormLabel>
                            {!categoryFormData?.selectedImg && (
                                <button
                                    onClick={() => imgRef.current.click()}
                                    className='flex justify-center items-center my-3 border-2 border-[#5E3EF5] py-2 px-4 text-[#5E3EF5] font-semibold text-base rounded-lg'>
                                    <BiImage className='me-2' size={16} />
                                    Select
                                </button>
                            )}
                            Hidden file input field
                            <input
                                ref={imgRef}
                                onChange={handleImageChange}
                                type='file'
                                accept="image/*"
                                className='hidden' // Hide the input, show button instead
                            />

                            Display the selected image
                            {categoryFormData?.selectedImg && (
                                <img
                                    src={categoryFormData?.selectedImg}
                                    onClick={() => imgRef.current.click()}
                                    alt="Selected"
                                    className='mt-4 w-32 h-32 object-cover rounded-lg'
                                />
                            )}
                        </FormControl> */}
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={4}
                            onClick={onReset}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={onHandleSubmit}
                            // onClick={handleSave}
                            colorScheme='blue'
                            isLoading={isLoading}
                            isDisabled={categoryFormData?.name ? false : true}
                        >
                            Add
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    )
}

export default AddCategoryModel