import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
} from '@chakra-ui/react'

const ConfirmRemoveTransaction = ({
    isOpen,
    onClose,
    isLoading,
    onDeleteTransaction
}) => {
    return (
        <>
            <Modal
                // initialFocusRef={initialRef}
                // finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Delete Payment</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Are you sure you want to delete this transaction?
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={4}
                            onClick={() => {
                                onDeleteTransaction();
                            }}
                            colorScheme="red"
                            isLoading={isLoading}
                            variant="ghost"
                            size="sm"
                        >
                            Delete
                        </Button>
                        <Button
                            colorScheme="blue"
                            variant="solid"
                            size="sm"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >

        </>
    )
}

export default ConfirmRemoveTransaction
