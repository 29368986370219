import React, { useState } from 'react';
import Modal from 'react-modal';

const ViewRequestLetter = ({ request }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [imageSrc, setImageSrc] = useState('');

    const handleImageClick = (src) => {
        setImageSrc(src);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <div>
            <img
                src={request?.requestLetter}
                alt="Description"
                // onError={(e) => e.target.src = 'path/to/fallback/image.jpg'}
                width="500"
                height="auto"
                onClick={() => handleImageClick(request?.requestLetter)}
                style={{ cursor: 'pointer' }}
            />

            {isModalOpen && (
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    contentLabel="Image Viewer"
                    style={{
                        overlay: { zIndex: 1000, backgroundColor: 'rgba(0, 0, 0, 0.8)' },
                        content: { background: 'none', border: 'none', inset: '0' },
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <img
                            src={imageSrc}
                            alt="Full Screen"
                            style={{ maxWidth: '50%', maxHeight: '50%' }}
                        />
                        <button
                            onClick={closeModal}
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                backgroundColor: 'white',
                                border: 'none',
                                padding: '10px',
                                borderRadius: '50%',
                                cursor: 'pointer',
                            }}
                        >
                            ✖
                        </button>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default ViewRequestLetter;
