import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Avatar,
    Select,
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    useToast,
    Spinner,
    Checkbox
} from '@chakra-ui/react';
import { DownloadIcon, SearchIcon } from '@chakra-ui/icons';
import { get } from '../../services/apis/api';
import PayslipModal from '../../components/hr/dialogBoxes/PayslipModal';
import GeneratePayroll from '../../components/hr/dialogBoxes/GeneratePayroll';

const Payroll = () => {
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    // Get current date and determine the default month and year
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // 0 for January, 11 for December
    const currentYear = currentDate.getFullYear();

    // If current month is January, set the default month to December of the previous year
    const defaultMonth = currentMonth === 0 ? 'December' : currentDate.toLocaleString('default', { month: 'long' });
    const defaultYear = currentMonth === 0 ? currentYear - 1 : currentYear;

    const [selectedMonth, setSelectedMonth] = useState(defaultMonth);
    const [selectedYear, setSelectedYear] = useState(defaultYear.toString());

    // SELECT ROWS LOGIC 
    const [selectedRows, setSelectedRows] = useState([]);

    // Handle select all
    const handleSelectAll = (e) => {
        try {
            if (e.target.checked) {
                // Select all rows
                setSelectedRows(data?.map((row) => row._id));
            } else {
                // Deselect all rows
                setSelectedRows([]);
            }
        } catch (e) {
            console.log(`ERROR WHILE SELECTING ALL ROWS ${e}`);
        }
    };

    // Handle row selection
    const handleRowSelect = (e, id) => {
        try {
            if (e.target.checked) {
                setSelectedRows((prev) => [...prev, id]);
            } else {
                setSelectedRows((prev) => prev.filter((rowId) => rowId !== id));
            }
        } catch (e) {
            console.log(`ERROR WHILE SELECTING  ROW ${e}`);
        }
    };

    // Check if all rows are selected
    const isAllSelected = selectedRows.length === data.length && data.length > 0;
    // SELECT ROWS LOGIC 

    const fetchData = async () => {
        setLoading(true);
        try {
            // Include month and year in the API call if selected
            const res = await get(
                `payroll/all?page=${page}&limit=${10}&search=${search}&month=${selectedMonth}&year=${selectedYear}`
            );
            if (res.statusCode === 200) {
                setData(res.data.data);
                setTotalPages(res.data.pages);
            }
        } catch (error) {
            console.error('Error fetching payroll data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, search, selectedMonth, selectedYear]);

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setPage(1); // Reset to the first page when searching
    };

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
        setPage(1); // Reset to the first page when filtering by month
    };

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
        setPage(1); // Reset to the first page when filtering by year
    };

    // console.log(selectedRows, 'sr------------>');


    return (
        <Box width="100%" p={4}>
            <Flex justify="space-between" align="center" mb={4}>
                <Box fontSize="xl" fontWeight="bold" mb={{ base: 4, md: 0 }}>
                    Payroll
                </Box>
                <Flex align="center">
                    <InputGroup mr={2} mb={{ base: 2, md: 0 }} w={{ base: "100%", md: "auto" }}>
                        <InputLeftElement pointerEvents="none">
                            <SearchIcon color="gray.300" />
                        </InputLeftElement>
                        <Input
                            type="text"
                            placeholder="Search"
                            value={search}
                            onChange={handleSearchChange}
                        />
                    </InputGroup>
                    <Select value={selectedYear} onChange={handleYearChange} w="100px" mr={2}>
                        {/* Add more years as options */}
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                    </Select>
                    <Select value={selectedMonth} onChange={handleMonthChange} w="150px" mr={2} placeholder="Select month">
                        {/* <option value="">None</option>  */}
                        <option value="0">January</option>
                        <option value="1">February</option>
                        <option value="2">March</option>
                        <option value="3">April</option>
                        <option value="4">May</option>
                        <option value="5">June</option>
                        <option value="6">July</option>
                        <option value="7">August</option>
                        <option value="8">September</option>
                        <option value="9">October</option>
                        <option value="10">November</option>
                        <option value="11">December</option>
                    </Select>

                    <GeneratePayroll fetchData={fetchData} />

                    <Button leftIcon={<DownloadIcon />} colorScheme="gray">
                        Download CSV
                    </Button>
                </Flex>
            </Flex>
            <Table className="w-full text-sm text-left text-gray-500">
                <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <Tr>
                        <Th>
                            <Checkbox
                                isChecked={isAllSelected}
                                onChange={handleSelectAll}
                            />
                        </Th>
                        <Th>Profile</Th>
                        <Th>Name</Th>
                        <Th>Employee Code</Th>
                        <Th>Paid Days</Th>
                        <Th>Payable Days</Th>
                        <Th>Gross Pay</Th>
                        <Th>Net Pay</Th>
                        <Th>Performance</Th>
                        <Th>Payslip</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {loading ? (
                        <Tr>
                            <Td colSpan="9" textAlign="center">Loading...</Td>
                        </Tr>
                    ) : (
                        data.map((row) => (
                            <Tr key={row._id}>
                                <Td>
                                    <Checkbox
                                        isChecked={selectedRows.includes(row._id)}
                                        onChange={(e) => handleRowSelect(e, row._id)}
                                    />
                                </Td>
                                <Td>
                                    <Avatar src="https://via.placeholder.com/150" name={row.employee.name} />
                                </Td>
                                <Td>{row?.employee?.name}</Td>
                                <Td>{row?.employee?.employeeCode || 'N/A'}</Td>
                                <Td>{row.paidDays}</Td>
                                <Td>{row.payableDays || 'N/A'}</Td>
                                <Td>{row.salaryBreakdown.grossPay}</Td>
                                <Td>{Math.floor(row.finalNetPay)}</Td>
                                <Td>{row.performance || 'N/A'}</Td>
                                <Td>
                                    <PayslipModal payslip={row} />
                                </Td>
                            </Tr>
                        ))
                    )}
                </Tbody>
            </Table>
            <Flex justify="space-between" mt={4}>
                <Button
                    onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                    disabled={page === 1}
                >
                    Previous
                </Button>
                <Box>
                    Page {page} of {totalPages}
                </Box>
                <Button
                    onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
                    disabled={page === totalPages}
                >
                    Next
                </Button>
            </Flex>
        </Box>
    );
};

export default Payroll;
