import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  GridItem,
  Stack,
  Text,
  chakra,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import Benefits from './Benefits';
import SalaryTerms from './SalaryTerms';
import PersonalInfo from './PersonalInfo';
import Basics from './Basics';
import { EmployeeState } from '../../../../context/addEmployee';
import axios from 'axios';
import { patchFormData, postFormData } from '../../../../services/apis/api';
import { t } from 'i18next';

const steps = [
  { label: 'basics', component: Basics },
  { label: 'personal-info', component: PersonalInfo },
  { label: 'salary-terms', component: SalaryTerms },
  { label: 'benefits', component: Benefits },
];

const EmployeeMultiStepForm = ({ onClose, isEdit, employee, getEmployeeDetails }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { basicDetails, setBasicDetails, personalInfo, setPersonalInfo, salaryTerms, setSalaryTerms, attachments, setAttachments, benefits, setBenefits } = EmployeeState();
  const toast = useToast();
  const isLastStep = currentStep === steps.length - 1;
  const isFirstStep = currentStep === 0;


  // console.log("basic details-->", basicDetails);


  const validateStep = () => {
    let newErrors = {};
    if (currentStep === 0) {
      // if (!basicDetails.location) newErrors.message = 'Location is required';
      if (!basicDetails.joiningDate) newErrors.message = 'Joining Date is required';
      if (!basicDetails.gender) newErrors.message = 'Gender is required';
      if (!basicDetails.designation) newErrors.message = 'Designation is required';
      if (!basicDetails.type) newErrors.message = 'Type is required';
      if (!basicDetails.department) newErrors.message = 'Department is required';
      if (!basicDetails.contactNo) newErrors.message = 'Contact No is required';
      if (!basicDetails.email) newErrors.message = 'Email ID is required';
      if (!basicDetails.fullName) newErrors.message = 'Full Name is required';
      if (!basicDetails.dateOfBirth) newErrors.message = 'dateOfBirthFull Name is required';
      if (!basicDetails.experience) newErrors.message = 'experience is required';
      // if (!basicDetails.reportingHead) newErrors.message = 'reportingHead is required';
      // if (!basicDetails.performancePlan) newErrors.message = 'performancePlan is required';
    }

    if (currentStep === 1) {
      // if (!personalInfo.drivingLicense) newErrors.message = 'Driving License is required';
      if (!personalInfo.govIdNumber) newErrors.message = 'govIdNumber is required';
      // if (!personalInfo.currentAddress) newErrors.message = 'Current Address is required';
      // if (!personalInfo.permanentAddress) newErrors.message = 'Permanent Address is required';
      // if (!personalInfo.payMethod) newErrors.message = 'Pay Method is required';
      if (!personalInfo.govIdExpiration) newErrors.message = 'govIdExpiration is required';
      // if (!personalInfo.alternateContact) newErrors.message = 'Alternate Contact is required';
      if (!personalInfo.agreements.agreeDetails) newErrors.message = 'Aggrement of self details is required';
      if (!personalInfo.agreements.agreeTerms) newErrors.message = 'Aggrement of Terms is required';
    }

    if (currentStep === 2) {
      if (!salaryTerms.netPay) salaryTerms.netPay = 0;
      if (!salaryTerms.workmenCompensation) salaryTerms.workmenCompensation = 0;
      if (!salaryTerms.gst) salaryTerms.gst = 0;
      if (!salaryTerms.tds) salaryTerms.tds = 0;
      if (!salaryTerms.bonus) salaryTerms.bonus = 0;
      if (!salaryTerms.gratuity) salaryTerms.gratuity = 0;
      if (!salaryTerms.esic) salaryTerms.esic = 0;
      if (!salaryTerms.pt) salaryTerms.pt = 0;
      if (!salaryTerms.pf) salaryTerms.pf = 0;
      if (!salaryTerms.variablePay) salaryTerms.variablePay = 0;
      if (!salaryTerms.grossPay) salaryTerms.grossPay = 0;
      if (!salaryTerms.basicSalary) salaryTerms.basicSalary = 0;
      if (!salaryTerms.telecommunicationAllowance) salaryTerms.telecommunicationAllowance = 0;
      if (!salaryTerms.specialAllowance) salaryTerms.specialAllowance = 0;
      if (!salaryTerms.hostelAllowance) salaryTerms.hostelAllowance = 0;
      if (!salaryTerms.travelingAllowance) salaryTerms.travelingAllowance = 0;
      if (!salaryTerms.hra) salaryTerms.hra = 0;
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast({
        title: 'Error',
        description: newErrors.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    let prevdoc = [];
    setLoading(true);
    const formData = new FormData();
    formData.append('name', basicDetails?.fullName);
    formData.append('email', basicDetails?.email);
    formData.append('mobile', basicDetails?.contactNo);
    formData.append('gender', basicDetails?.gender);
    formData.append('department', basicDetails?.department);
    formData.append('type', basicDetails?.type);
    formData.append('designation', basicDetails?.designation);
    formData.append('joiningDate', basicDetails?.joiningDate);
    formData.append('dateOfBirth', basicDetails?.dateOfBirth);
    formData.append('experience', basicDetails?.experience);
    formData.append('performancePlan', basicDetails?.performancePlan || undefined);
    formData.append('reportingHead', basicDetails?.reportingHead || undefined);
    formData.append('status', basicDetails?.status);
    formData.append('location', basicDetails?.location);
    formData.append('alternateContactNo', personalInfo?.alternateContact);
    formData.append('govIdExpiration', personalInfo?.govIdExpiration);
    formData.append('govIdNumber', personalInfo?.govIdNumber);
    formData.append('drivingLicense', personalInfo?.drivingLicense);
    formData.append('payMethod', personalInfo?.payMethod);
    formData.append('holderName', personalInfo?.holderName);
    formData.append('bankName', personalInfo?.bankName);
    formData.append('accountNo', personalInfo?.accountNo);
    formData.append('permanentAddress', personalInfo?.permanentAddress);
    formData.append('currentAddress', personalInfo?.currentAddress);
    personalInfo.documents.forEach(file => {
      typeof file === 'string' ? prevdoc.push(file) : formData.append('attachments', file)
    });
    formData.append('prevDoc', JSON.stringify(prevdoc))
    formData.append('agreements[agreeDetails]', personalInfo?.agreements?.agreeDetails);
    formData.append('agreements[agreeTerms]', personalInfo?.agreements?.agreeTerms);
    formData.append('allowances[hra]', salaryTerms?.hra);
    formData.append('allowances[travelingAllowance]', salaryTerms?.travelingAllowance);
    formData.append('allowances[hostelAllowance]', salaryTerms?.hostelAllowance);
    formData.append('allowances[specialAllowance]', salaryTerms?.specialAllowance);
    formData.append('allowances[telecommunicationAllowance]', salaryTerms?.telecommunicationAllowance);
    formData.append('allowances[basicSalary]', salaryTerms?.basicSalary);
    formData.append('grossPay', salaryTerms?.grossPay);
    formData.append('variablePay', salaryTerms?.variablePay);
    formData.append('applicableActs[pf]', salaryTerms?.pf);
    formData.append('applicableActs[pt]', salaryTerms?.pt);
    formData.append('applicableActs[esic]', salaryTerms?.esic);
    formData.append('applicableActs[gratuity]', salaryTerms?.gratuity);
    formData.append('applicableActs[bonus]', salaryTerms?.bonus);
    formData.append('applicableActs[tds]', salaryTerms?.tds);
    formData.append('applicableActs[gst]', salaryTerms?.gst);
    formData.append('applicableActs[workmenCompensation]', salaryTerms?.workmenCompensation);
    formData.append('netPay', salaryTerms?.netPay);
    formData.append('ctc', salaryTerms?.ctc);
    formData.append('appraisalDate', salaryTerms?.appraisalDate);
    for (const key in benefits) {
      if (benefits[key] !== undefined) {
        formData.append(`benefits[${key}]`, benefits[key]);
      }
    }

    try {
      let res;
      if (isEdit) {
        res = await patchFormData(`${employee.role === 'COACH' ? 'coach' : 'employee'}/update/${employee._id}`, formData);
        if (res.statusCode === 201 || res.statusCode === 200) {
          setLoading(false)
          getEmployeeDetails();
          toast({
            title: 'Success',
            description: 'Employee data submitted successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          onClose();
        }
      } else {
        res = await postFormData('employee/add', formData);
        if (res.statusCode === 201 || res.statusCode === 200) {
          setLoading(false)
          toast({
            title: 'Success',
            description: 'Employee data submitted successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          onClose();
        }
      }
    } catch (error) {
      setLoading(false)
      toast({
        title: 'Error',
        description: error?.response?.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const goToNextStep = () => {
    if (validateStep()) {
      if (isLastStep) {
        handleSubmit();
      } else {
        setCurrentStep((prev) => prev + 1);
      }
    }
  };

  const goToPreviousStep = () => {
    if (!isFirstStep) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const CurrentComponent = steps[currentStep].component;

  return (
    <Box mx="auto" py={4}>
      <Flex justify="space-between" align="center" mb={2} position="relative">
        {steps?.map((step, index) => (
          <Flex key={index} direction="column" align="center" flex="1">
            <Box position="relative" display="flex" alignItems="center" justifyContent="center">
              {index < steps.length - 1 && (
                <Box
                  position="absolute"
                  left="50%"
                  top="50%"
                  height="2px"
                  width="calc(100% - 2rem)"
                  bg={index < currentStep ? 'blue.500' : 'gray.200'}
                  transform="translateX(50%)"
                  zIndex={-1}
                />
              )}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                w={8}
                h={8}
                borderWidth={"1px"}
                borderColor={index < currentStep ? 'green' : index === currentStep ? "blue" : 'gray.500'}
                borderRadius="50%"
                bg={index < currentStep ? 'white' : index === currentStep ? 'blue.100' : 'white'}
                color={index < currentStep ? 'green' : index === currentStep ? "blue" : 'gray.500'}
              >
                {index < currentStep ? <CheckCircleIcon w={"100%"} h={"100%"} className='font-light' /> : index + 1}
              </Box>
            </Box>
            <Text
              mt={2}
              fontSize="sm"
              fontWeight={index === currentStep ? 'bold' : 'normal'}
              color={index < currentStep ? 'green' : index === currentStep ? "blue" : 'gray.500'}
            >
              {t(step?.label)}
            </Text>
          </Flex>
        ))}
      </Flex>

      <CurrentComponent
        basicDetails={basicDetails}
        setBasicDetails={setBasicDetails}
        personalInfo={personalInfo}
        setPersonalInfo={setPersonalInfo}
        salaryTerms={salaryTerms}
        setSalaryTerms={setSalaryTerms}
        attachments={attachments}
        setAttachments={setAttachments}
        errors={errors}
      />

      <Flex mt={10} justify={isFirstStep ? "end" : "space-between"}>
        {!isFirstStep && (
          <Button onClick={goToPreviousStep} colorScheme="blue" variant="outline">
            {t("previous")}
          </Button>
        )}
        <Button onClick={goToNextStep} colorScheme="blue">
          {isLastStep ? loading ? <Spinner /> : t('submit') : t("next")}
        </Button>
      </Flex>
    </Box>
  );
};

export default EmployeeMultiStepForm;
