import { RadioGroup, Stack, Radio } from '@chakra-ui/react';

const SelectTransactionStateComponent = ({ selectedStatus, onSelelectState }) => {
    return (
        <RadioGroup
            onChange={(e) => {
                onSelelectState(e)
            }}
            value={selectedStatus}
        >
            <Stack direction="row" spacing={5}>
                <Radio value="UNPAID">Unpaid</Radio>
                <Radio value="PAID">Paid</Radio>
                {/* <Radio value="pay-later">Pay Later</Radio> */}
            </Stack>
        </RadioGroup>
    );
};

export default SelectTransactionStateComponent;
