import React from 'react'
import { Routes, Route } from "react-router-dom";
import CommanNav from '../components/globles/CommanNav';
import Home from '../pages/operations/Home';
import Expenses from '../pages/account/Expenses';
import FinancialRequests from '../pages/account/FinancialRequests';
import EarningsAndIncome from '../pages/account/EarningsIncomes';
import Payroll from '../pages/account/Payroll';
import CategoryDetail from '../pages/account/CategoryDetail';
import AdditionalIncome from '../pages/account/AdditionalIncome';
import ReportsAndSummery from '../pages/account/Reports&Summery';
import Assets from '../pages/account/Assets';
import ExpensesList from '../pages/account/ExpensesList';
import AdditionalExpenses from '../pages/account/AdditionalExpense';
import ExpenseCategoryDetail from '../pages/account/expenseCategoryDetails';
import BalanceSheet from '../pages/account/BalanceSheet';
import AcademyDetail from '../pages/account/directIncomeCategory/AcademyDetail';
import TournamentDetail from '../pages/account/directIncomeCategory/TournamentDetail';
import VipClubDetail from '../pages/account/directIncomeCategory/VipClubDetail';
import StoreDetail from '../pages/account/directIncomeCategory/StoreDetail';
import ClinicDetail from '../pages/account/directIncomeCategory/ClinicDetail';
import OverDuePayments from '../pages/account/OverDuePayments';

const Account = () => {
  return (
    <Routes>
      <Route path="/account" element={<CommanNav />}>
        <Route index element={<Home />} />
        <Route path='request' element={<FinancialRequests />} />
        <Route path='balancesheet' element={<BalanceSheet />} />
        <Route path='expenses'>
          <Route index element={<Expenses />} />
          <Route path=':id' element={<ExpensesList />} />
          <Route path=':category'>
            <Route path=':id' element={<ExpenseCategoryDetail />} />
          </Route>
        </Route>
        <Route path='payroll' element={<Payroll />} />
        <Route path='assets' element={<Assets />} />
        <Route path='reports' element={<ReportsAndSummery />} />
        <Route path='overdues' element={<OverDuePayments />} />
        <Route path='additional-income' element={<AdditionalIncome />} />
        <Route path='additional-expenses' element={<AdditionalExpenses />} />
        
        <Route path='earnings'>
          <Route path='direct'>
            <Route path='academy' element={<AcademyDetail />} />
            <Route path='winning-tournament' element={<TournamentDetail />} />
            <Route path='vip-club' element={<VipClubDetail />} />
            <Route path='store' element={<StoreDetail />} />
            <Route path='clinic' element={<ClinicDetail />} />
          </Route>
          <Route index element={<EarningsAndIncome />} />
          <Route path=':category'>
            <Route path=':id' element={<CategoryDetail />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}

export default Account

