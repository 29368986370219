import React, { useState } from 'react'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    useDisclosure,
    Button,
    Tooltip,
    Input,
    useToast,
} from '@chakra-ui/react'
import { deleteToken } from '../../services/apis/token'
import { useNavigate } from 'react-router-dom'
import { UserState } from '../../context/user'
import { RxCrossCircled } from 'react-icons/rx'
import { patch, post } from '../../services/apis/api'
import { useTranslation } from 'react-i18next'

const ApproveCorporateRequest = ({ request, fetchData }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const { user, setUser } = UserState();
    const cancelRef = React.useRef()
    const navigate = useNavigate();
    const [reason, setReason] = useState('');

    // REJECT PAYSLIP REQUEST 
    const rejectCorporateRequest = async () => {
        try {
            const response = await post(`clinic/close/${request?._id}`);

            if (response.statusCode === 200) {
                fetchData();
                navigate('/clinic/appointments');
                onClose();
                toast({
                    title: 'Successfull...!',
                    description: 'Request  Closed!',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (e) {
            console.log(`ERROR WHILE UPDATING PAYSLIP DATA : ${e}`);
        }
    }

    return (
        <>
            <Tooltip label="Close Request" aria-label="Reject tooltip">
                <Button
                    colorScheme="blue"
                    aria-label="Reject"
                    onClick={onOpen}
                    mr={3}
                >
                    {t('approve-request')}
                </Button>
            </Tooltip>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Approve Request
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to Approve this request ?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button
                                variant={"ghost"}
                                colorScheme='red'
                                onClick={() => {
                                    rejectCorporateRequest();
                                }}
                                mr={3}
                            >
                                REJECT
                            </Button>
                            <Button
                                ref={cancelRef}
                                onClick={onClose}
                                variant="solid"
                                colorScheme='blue'
                            >
                                Cancel
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default ApproveCorporateRequest