import React, { useEffect, useState } from 'react'
import CategoryHeader from '../../components/account/CategoryHeader'
import { useTranslation } from 'react-i18next'
import { Button, useDisclosure, useToast } from '@chakra-ui/react'
import { UserState } from "../../context/user";
import TableComponent from '../../components/account/TableComponent';
import AddEarningsHistory from '../../components/account/AddEarningHistory';
import { get, patch, post } from '../../services/apis/api';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { CategoryDetailSkeleton } from '../../components/skeletons/Skeletons';
import BasicDataComponent from '../../components/account/directIncomeCategory/BasicDataComponent';


const Heading = ({ title }) => (
    <h2 className="text-base text-gray-600 mb-2 lg:text-xl font-bold">{title}</h2>
)

// TABLE COLUMNS 
const columns = [
    's-no',
    'Date-of-entry',
    'amount',
    'payment-method',
    'payment-id',
    'created-by',
    'description',
    // 'Actions' //QUETIONS IT SHOLD GIVE HERE OR NOT 
];

const _formData = {
    entryDate: '',
    amount: null,
    description: '',
    transactionType: 'DR',
    paymentType: 'offline',
    paymentMethod: 'cash',
    paymentId: '',
    paymentState: 'UNPAID'
}

const ExpenseCategoryDetail = () => {
    const { user } = UserState();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { t, i18n } = useTranslation();
    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();
    const _id = location.pathname.split('/')[4];
    const [categoryData, setCategoryData] = useState(null)
    const [transactionsData, setTransactionsData] = useState(null)
    const [isLoading, setIsLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({ ..._formData })
    const [allPendingSubs, setAllPendingSubs] = useState([])
    const [selectedSubs, setSelectedSubs] = React.useState(null);
    const [page, setPage] = useState(1);
    const [skip, setSkip] = useState(0); // Initial skip should be 0
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [totalTransactionsAmount, setTotalTransactionsAmount] = useState(0);

    //    FETCH DATA FOR EARNING HISTORY TABLE
    useEffect(() => {
        getCategoryDataById(_id);
    }, [skip, limit, selectedYear])

    // GET CATEGORY DATA BY CATEGORY ID
    const getCategoryDataById = async (_id) => {
        try {
            const response = await get(`expense-category/details/${_id}?skip=${skip}&limit=${limit}&year=${selectedYear}`)
            if (response?.statusCode == 200) {
                setCategoryData(response?.data?.details);
                setTransactionsData(response?.data?.transactions);
                const totalCount = response?.data?.total_count || 0; // Ensure totalCount is defined
                setTotalPages(Math.ceil(totalCount / limit));
                setTotalTransactionsAmount(response?.data?.totalExpensesAmount);
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING DATA:${e}`);
        }
    }

    // ADD NEW EARNING HISTORY  
    const addNewExpense = async (_id) => {
        setIsLoading(true)
        try {
            const _payload = {
                ...formData,
                expenseCategoryId: _id,
            }
            const res = await post(`expense-category/add-expense`, _payload)
            // console.log(updarePlayerPaymentStatusResponse, 'UPDATE PAYMENT STATUS RESPONSE');
            setIsLoading(false);
            if (res.statusCode === 201 || res.statusCode === 200) {
                toast({
                    title: 'Success!',
                    description: res.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                setFormData({ ..._formData });
                // getTransactionsDataById(_id);
                getCategoryDataById(_id);
                onClose();
            }
        } catch (e) {
            setIsLoading(false);
            console.log(`ERROR WHILE FETCHING DATA:${e}`);

        }
    }

    return (
        <div className='p-2 lg:p-4'>
            {/* <CategoryHeader /> */}
            <button onClick={() => { navigate(-1) }} className="px-4 py-2 text-white bg-[#290dbd] rounded-md hover:bg-[#1d0a8c]">
                {t("back")}
            </button>
            {categoryData ?
                <div className='w-full flex items-start justify-between py-4 lg:py-6 '>
                    <BasicDataComponent
                        basicData={categoryData}
                        isDesc={true}
                        selectedYear={selectedYear}
                        setSelectedYear={setSelectedYear}
                        totalAmount={totalTransactionsAmount}
                        onAddOpen={() => {
                            onOpen();
                        }}
                        isExpense={true}
                    />
                    <AddEarningsHistory
                        onOpen={onOpen}
                        placeholder="add-new-expense"
                        isOpen={isOpen}
                        onClose={onClose}
                        formData={formData}
                        selectedSubs={selectedSubs}
                        setSelectedSubs={setSelectedSubs}
                        allPendingSubs={allPendingSubs}
                        category={categoryData?.name}
                        onHandleChange={(field, value) => {
                            setFormData((prevData) => ({
                                ...prevData,
                                [field]: value
                            }));
                        }}
                        isLoading={isLoading}
                        onReset={() => {
                            setFormData({ ..._formData });
                            onClose();
                        }}
                        onHandleSubmit={() => addNewExpense(_id)}
                    />
                </div>
                :
                <div className='my-6'>
                    <CategoryDetailSkeleton />
                </div>
            }
            <div className="w-full flex flex-col items-start gap-3 flex-1 min-h-[50vh]">
                <Heading
                    title={t("expenses")}
                />
                <div className='w-full flex-1 flex flex-col '>
                    <TableComponent
                        page={page}
                        skip={skip}
                        limit={limit}
                        setSkip={setSkip}
                        setLimit={setLimit}
                        setPage={setPage}
                        columns={columns}
                        rows={transactionsData || []}
                        totalPages={totalPages}
                    />
                </div>
            </div>
        </div>
    )
}

export default ExpenseCategoryDetail
