import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    Input,
    FormLabel,
    Button,
    Select,
    useToast,
} from '@chakra-ui/react'

import React, { useState } from 'react'
import { post } from '../../services/apis/api'
import { UserState } from '../../context/user';
import { useTranslation } from 'react-i18next';



function RequestPayment({ request, fetchData }) {
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [error, setError] = useState('')
    const [amount, setamount] = useState('')
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)

    const onRequestPayment = async () => {
        setIsLoading(true);
        try {
            const response = await post(`clinic/request-payment/${request?._id}`, { amount });
            setIsLoading(false);
            if (response.statusCode === 200) {
                toast({
                    title: 'Successfull...!',
                    description: response.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                fetchData();
                onClose();
            }
        } catch (e) {
            setIsLoading(false);
            console.log(`ERROR WHILE REQUESTING PAYMENT ${e}`);

        }
    }

    return (
        <>
            <Button
                variant={!request?.paymentRequested ? 'solid' : 'ghost'}
                colorScheme="blue"
                onClick={() => {
                    if (!request?.paymentRequested) {
                        onOpen();
                    }
                }}
            >
                {request?.paymentRequested  ? t('payment-requested') : t('request-payment')}
            </Button>
            {/* <Button >Open Modal</Button> */}
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Request Amount</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>Enter Amount</FormLabel>
                            <Input
                                value={amount}
                                type='number'
                                ref={initialRef}
                                placeholder='Amount'
                                isInvalid={error !== ''}
                                onChange={(e) => {
                                    setamount(e.target.value)
                                }}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={4}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={onRequestPayment}
                            colorScheme='blue'
                            isLoading={isLoading}
                            isDisabled={error !== ''}
                        >
                            Schedule
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    )
}

export default RequestPayment