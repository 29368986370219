import React, { useState } from 'react'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    useDisclosure,
    Button,
    Tooltip,
    Input,
    useToast,
} from '@chakra-ui/react'
import { deleteToken } from '../../services/apis/token'
import { useNavigate } from 'react-router-dom'
import { UserState } from '../../context/user'
import { RxCrossCircled } from 'react-icons/rx'
import { patch, post } from '../../services/apis/api'
import { useTranslation } from 'react-i18next'

const RejectCorporateRequest = ({ request, fetchData }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const { user, setUser } = UserState();
    const cancelRef = React.useRef()
    const navigate = useNavigate();
    const [reason, setReason] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // REJECT PAYSLIP REQUEST 
    const rejectCorporateRequest = async () => {
        setIsLoading(true);
        try {
            let res
            const _payload = {
                action: "REJECTED"
            }
            res = await post(`request/update-corporate-request/${request?._id}`, _payload);
            setIsLoading(false);
            if (res.statusCode === 200) {
                fetchData();
                onClose();
                toast({
                    title: "Request Rejected successfully.",
                    status: "success",
                    duration: 5000, // 5 seconds    
                    isClosable: true,
                });
            }
        } catch (error) {
            setIsLoading(false);
            toast({
                title: error?.response?.data?.message || "Request rejection failed.",
                status: "error",
                duration: 5000, // 5 seconds    
                isClosable: true,
            });
        }
    }

    return (
        <>
            <Tooltip label="Close Request" aria-label="Reject tooltip">
                <Button
                    colorScheme="red"
                    aria-label="Reject"
                    onClick={onOpen}
                    mr={3}
                    isLoading={isLoading}
                >
                    {t('reject-request')}
                </Button>
            </Tooltip>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Reject Request
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to reject this request ?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button
                                variant={"ghost"}
                                colorScheme='red'
                                onClick={() => {
                                    rejectCorporateRequest();
                                }}
                                mr={3}
                                isLoading={isLoading}
                            >
                                REJECT
                            </Button>
                            <Button
                                ref={cancelRef}
                                onClick={onClose}
                                variant="solid"
                                colorScheme='blue'
                            >
                                Cancel
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default RejectCorporateRequest