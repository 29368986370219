import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    Input,
    FormLabel,
    Button,
    Select,
    useToast,
    Textarea
} from '@chakra-ui/react'
import React from 'react'


function AddExpenseCategoryModel({ isOpen, onClose, onReset, expenseFormData, onHandleChange, isLoading, onHandleSubmit }) {

    return (
        <>
            {/* <Button >Open Modal</Button> */}
            <Modal
                // initialFocusRef={initialRef}
                // finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create New Expense Category</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>Expense Category Name</FormLabel>
                            <Input
                                value={expenseFormData?.name}
                                onChange={(e) => {
                                    onHandleChange('name', e.target.value)
                                }}
                                placeholder='Category Name'
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Expense Category Description</FormLabel>
                            <Textarea
                                value={expenseFormData?.description}
                                onChange={(e) => {
                                    onHandleChange('description', e.target.value)
                                }}
                                placeholder='Category Description'
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={4}
                            onClick={onReset}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={onHandleSubmit}
                            // onClick={handleSave}
                            colorScheme='blue'
                            isLoading={isLoading}
                            isDisabled={expenseFormData?.name ? false : true}
                        >
                            Add
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    )
}

export default AddExpenseCategoryModel