'use client'

import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useToast,
  Spinner,
} from '@chakra-ui/react'
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi'
import { RiUserSettingsLine } from "react-icons/ri";
import { MdOutlineAccountBalance } from "react-icons/md";
import { BsPeople, BsLayoutTextSidebarReverse, BsCart4 } from "react-icons/bs";
import { IconType } from 'react-icons'
import { FaUsersGear } from "react-icons/fa6";
import { GrUserAdmin, GrUserManager } from "react-icons/gr";
import logo from '../../assets/images/logo-rbg.png'
import { Link, Outlet, useNavigate } from 'react-router-dom';
import ConfirmBox from '../alerts/ConfirmBox';
import { PiGameController } from 'react-icons/pi';
import { FaClinicMedical } from 'react-icons/fa';
import { UserState } from '../../context/user';
import { getToken } from '../../services/apis/token';
import { get } from '../../services/apis/api';
import { useEffect } from 'react';
import { TbLockCog } from 'react-icons/tb';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import colors from '../../utills/appTheme';
import ContactMahCompany from '../contactUs/ContactMahCompany';
import ChangeLanguage from '../settings/ChangeLanguage';

const LinkItems = [
  { name: 'operation', icon: FaUsersGear, route: '/operation' },
  { name: 'account', icon: MdOutlineAccountBalance, route: '/account' },
  { name: 'human-resource', icon: BsPeople, route: '/hr' },
  { name: 'media-center', icon: BsLayoutTextSidebarReverse, route: '/media-center' },
  { name: 'store', icon: BsCart4, route: '/inventory' },
  { name: 'game-management', icon: PiGameController, route: '/game-management' },
  { name: 'clinic', icon: FaClinicMedical, route: '/clinic' },
]

const SidebarContent = ({ onClose, ...rest }) => {
  const { i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  return (
    <Box
      transition="3s ease"
      bg={'#f8f9fa'}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      pt={6}
      {...rest}>
      <div className='w-full flex justify-end pe-4'>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </div>
      <div className='w-full flex justify-center'>
        <Box h={28} w={32} className='border-[#e9ecef] border-b-[1px] px-4 pb-2'>
          <Link to={'/dashboard'}>
            <img className='h-full w-full' src={logo} />
          </Link>
        </Box>
      </div>
      <div className={`mt-2 h-[80vh] overflow-y-scroll${isArabic ? 'rtl' : 'ltr'}`}>
        {LinkItems.map((link) => (
          <NavItem key={link.name} isArabic={isArabic} icon={link.icon} link={link.route} onClose={onClose}>
            {t(link.name)}
          </NavItem>
        ))}
        {/* <ContactMahCompany /> */}
      </div>
    </Box>
  )
}

const NavItem = ({ icon, link, isArabic, children, onClose, ...rest }) => {
  return (
    <div className="group">
      <Box style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
        <Link to={link} onClick={onClose}>
          <Flex
            className={`text-sm font-semibold ps-4 py-3 my-2 mx-5 rounded-md hover:shadow-sm flex items-center ${isArabic ? 'flex-row-reverse' : 'flex-row'}`}
            cursor="pointer"
            color={'#67748e'}
            _hover={{
              bg: '#ffffff',
              color: 'black',
            }}
            {...rest}
          >
            {icon && (
              <Icon
                mx="4"
                fontSize="16"
                borderRadius="10px"
                bg="#ffffff"
                p={2}
                h={8}
                w={8}
                color={colors.primary}
                _groupHover={{
                  color: "white",
                  backgroundImage: colors.hoverGradient,
                }}
                as={icon}
              />
            )}
            {children}
          </Flex>
        </Link>
      </Box>
    </div>
  )
} 

const MobileNav = ({ onOpen, user, ...rest }) => {
  const { i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={'#f8f9fa'}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <HStack spacing={{ base: '0', md: '6' }}>
        <IconButton size="lg" variant="ghost" aria-label="open menu" icon={<FiBell />} />
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} pe={4} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack>
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2">
                  <Text fontSize="sm" className='text-sm font-medium'>{user?.name}</Text>
                  <Text fontSize="xs" color="gray.600" className='font-semibold text-sm'>
                    {user?.jobTitle}
                  </Text>
                </VStack>
                <Avatar size={'sm'} src={user?.pic} />
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown className='font-medium' />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              justifyContent={'center'}
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
              className={`${isArabic ? 'rtl' : 'ltr'}`}
            >
              <MenuItem>
                <Link to={'/dashboard/settings'} className={`flex w-full items-center ${isArabic ? 'flex-row-reverse' : 'flex-row'}`}>
                  <FiSettings className={"mx-4"} /> {t("settings")}
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/update-password'} className={`flex w-full items-center ${isArabic ? 'flex-row-reverse' : 'flex-row'}`}>
                  <TbLockCog className={"mx-4"} /> {t("update-password")}
                </Link>
              </MenuItem>
              <div style={{ margin: '10px' }}>
                {t("change-language")}
                <ChangeLanguage />
              </div>
              <MenuDivider />
              <MenuItem onClick={() => { }}>
                <ConfirmBox />
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  )
}

const SidebarWithHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { user, setUser } = UserState();
  const toast = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    getUSer();
  }, []);

  const getUSer = async () => {
    const tkn = sessionStorage.getItem("token") || localStorage.getItem("token");
    const token = JSON.parse(tkn);
    if (!token) {
      navigate("/login");
    } else {
      try {
        const res = await get('user/get');
        if (res.statusCode === 200) {
          setUser(res.data.user);
        }
      } catch (error) {
        // navigate("/login");
        // toast({
        //   title: "Error Occured",
        //   description: error?.response?.data?.message,
        //   status: "error",
        //   duration: 5000,
        //   isClosable: true,
        // });
      }
    }
  };

  useEffect(() => {
    if (!user) {
      // If there's no user, handle redirection or fetching user logic here
      // For now, we'll just console log for debugging
      console.log("No user found, redirect or fetch user details here");
    }
  }, [user]);

  if (!user) {
    return (
      <div className='w-screen h-screen flex justify-center items-center'>
        <Spinner size={"xl"} />
      </div>
    )
  }

  return (
    <Box minH="100vh" bg={'#ffffff'}>
      <SidebarContent onClose={onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* Mobile nav */}
      <MobileNav user={user} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <Outlet />
      </Box>
    </Box>
  )
}

export default SidebarWithHeader
