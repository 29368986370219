import { FormControl } from '@chakra-ui/react';
import { Select as ReactSelect } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';
import { useLocation } from 'react-router-dom';

const SearchableSelectComponent = ({
    placeholder,
    isArabic,
    allOptions,
    fetchSelectedData,
    fetchTransactionsData,
    fetchData,
    skip,
    limit,
    setSkip,
    setLimit,
    setPage,
    setSelectedId
}) => {
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    const location = useLocation();

    const options = allOptions?.length > 0
        ? allOptions?.map((opt) => ({
            label: t(opt?.userName || opt?.name),
            value: opt?._id,
        })) : []

    // HABDLE SELECT CHANGE 
    const handleChange = (selectedOption) => {
        if (selectedOption) {
            const value = selectedOption.value;
            setSkip(0);
            setLimit(10);
            setPage(1);
            fetchSelectedData(value, 0, 10);
            setSelectedId(value);
        } else {
            // setSelectedData(null);
            if (location?.pathname?.includes('game') || location?.pathname?.includes('player')) {
                fetchTransactionsData(skip, limit);
            } else {
                fetchData(skip, limit);
            }
        }
    };

    return (
        <FormControl>
            <ReactSelect
                placeholder={t(placeholder)}
                options={options}
                isClearable
                isSearchable
                styles={{
                    control: (provided) => ({
                        ...provided,
                        textAlign: isArabic ? 'right' : 'left',
                        background: '#f9fafb',
                        borderColor: 'gray.300',
                    }),
                }}
                onChange={handleChange}
            />
        </FormControl>
    );
};

export default SearchableSelectComponent;
